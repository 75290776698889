.el-button
  border-radius: var(--button-border-radius)
  padding: 8px 15px
  font-weight: 700
  font-family: var(--font-family)
  font-size: var(--font-size)
  line-height: 1.33
  min-width: 100px
  text-align: center
  color: var(--brand)
  background: var(--white)
  transition-duration: .3s
  transition-property: color, background
  border: none

  @media #{$M_XS}
    padding: 6px 12px
    min-width: 80px

  &:hover, &:focus, &:active
    background: var(--brand)
    color: var(--white)

  &.disabled, &[disabled], &.is-disabled
    color: var(--gray)
    background: var(--white)
    cursor: default

  &.gray
    background: var(--white-dark)
    color: var(--brand)

    &:hover, &:focus, &:active
      background: var(--brand)
      color: var(--white)

    &.disabled, &[disabled]
      color: var(--gray)
      background: var(--white-dark)

  &.no-width
    min-width: auto

  &--primary
    background: var(--brand)
    color: var(--white)

    &:hover, &:focus, &:active
      background: var(--brand-dark-light)

    &.disabled, &[disabled], .is-disabled, &.is-disabled[disabled]
      color: var(--gray)
      background: var(--white-dark)

  &--text
    background: none
    color: var(--text-primary-default)

    &:hover, &:focus, &:active
      background: none
      color: var(--brand-dark-light)

    &.disabled, &[disabled]
      color: var(--text-primary-disabled)
      background: none

      &:hover, &:focus
        color: var(--text-primary-disabled)

  &--danger
    background: var(--red)
    color: var(--white)

    &:hover, &:focus, &:active
      background: var(--red-lighter)

    &.disabled, &[disabled]
      color: var(--gray)
      background: var(--red-light)

  &--mini
    min-width: 50px
    padding: 4px 8px
    font-size: calc(var(--font-size) - 2px)

  &.is-round
    border-radius: var(--button-round-border-radius)
    padding: 8px 15px

    @media #{$M_XS}
      padding: 6px 12px

  &.is-plain
    color: var(--text-primary-default)
    background: var(--background-primary-soft-default)

    &:hover, &:focus
      background: var(--background-primary-soft-hover)
      color: var(--text-primary-default)
