.el-input
  &__inner
    background: var(--white)
    border-radius: var(--input-border-radius)
    border: 2px solid var(--ultra-light)
    height: 32px
    line-height: 32px
    color: var(--dark)
    font-family: var(--font-family)

    @media #{$M_XS}
      font-size: 12px
      height: 28px
      line-height: 28px

    &:hover, &:focus
      border-color: var(--ultra-light)

  &.is-disabled .el-input__inner
    color: var(--disabled-text)

  &__suffix
    display: flex
    align-items: center

  &.search-input
    &.el-input--prefix
      .el-input__inner
        padding-left: 36px

    .el-input__prefix
      display: flex
      align-items: center
      justify-content: center
      font-size: 20px
      color: var(--icon-secondary-default)
      left: 8px

  .header &
    &__inner
      font-size: var(--font-size)
      font-weight: bold
      border-radius: 0
      border: none

      @media #{$M_XS}
        font-size: 12px

      &::placeholder
        color: var(--white)
        font-weight: normal

        @media #{$M_XS}
          font-size: 12px

  &.dark, .dark &
    &__inner
      background: var(--brand-dark)
      color: var(--white)

  &.light, .light &
    &__inner
      background-color: var(--white-dark)

  &.input-transparent, .input-transparent &
    &__inner
      border-color: transparent

      &:hover
        border-color: var(--ultra-light)

      &:focus
        border: 1px solid var(--brand)
