.three-line-loader
  display: block
  position: relative
  left: 50%
  top: 50%
  width: 150px
  height: 150px
  margin: -75px 0 0 -75px
  border-radius: 50%
  border: 3px solid transparent
  border-top-color: var(--brand)
  -webkit-animation: spin 2s linear infinite
  animation: spin 2s linear infinite

  &:before
    content: ""
    position: absolute
    top: 5px
    left: 5px
    right: 5px
    bottom: 5px
    border-radius: 50%
    border: 3px solid transparent
    border-top-color: var(--brand-light)
    -webkit-animation: spin 3s linear infinite
    animation: spin 3s linear infinite

  &:after
    content: ""
    position: absolute
    top: 15px
    left: 15px
    right: 15px
    bottom: 15px
    border-radius: 50%
    border: 3px solid transparent
    border-top-color: var(--brand-dark-light)
    -webkit-animation: spin 1.5s linear infinite
    animation: spin 1.5s linear infinite

@-webkit-keyframes spin
  0%
    -webkit-transform: rotate(0deg)
    -ms-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)
    -ms-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes spin
  0%
    -webkit-transform: rotate(0deg)
    -ms-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)
    -ms-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes fadeInUp
  from
    opacity: 0
    transform: translate3d(0, 20%, 0)

    to
      opacity: 1
      transform: translate3d(0, 0, 0)

.fadeInUp-enter-active, .fadeInUp-leave-active
  animation-duration: .2s
  animation-fill-mode: both
  animation-name: fadeInUp

.fadeInUp-leave-active
  animation-direction: reverse
