.el-tooltip__popper
  max-width: 250px
  font-size: 12px

  .popper__arrow
    display: none

  &.is-light
    color: var(--gray-dark)
    background: var(--white)
    border: 1px solid var(--white)
    box-shadow: var(--light-color-box-shadow)

  &.is-dark
    background: var(--brand-dark)
    box-shadow: var(--dark-color-box-shadow)

  // Retention tooltip
  &.retention-tooltip
    font-size: var(--font-size)

    .date
      color: var(--gray)
      font-size: 11px
      display: inline-block
      margin-top: 3px

  &.filter-tooltip
    max-width: 400px

  &.gallery-tooltip-popper
    max-width: 440px

  &.name-description-tooltip
    max-width: 600px

  .load-all
    color: var(--brand)

    &:hover
      text-decoration: underline

  &.custom
    font-family: var(--font-family)
    visibility: hidden
    opacity: 0
    min-width: max-content
    position: absolute
    top: -45px
    left: -38px
    z-index: 3

    &:after
      content: ""
      display: block
      position: absolute
      width: 12px
      height: 12px
      background: var(--white)
      left: calc(50% - 6px)
      bottom: -4px
      transform: rotate(45deg)
