.el-upload
  width: 100%

  .el-upload-dragger
    width: 100%

  &:focus .el-upload-dragger
    border-color: var(--brand)

.el-upload-dragger
  border-color: var(--gray-dark-light)
  transition: border-color var(--transition)

  &:hover,
  &:focus
    border-color: var(--brand)

  .el-icon-upload
    color: var(--gray-dark-light)

  .el-upload__text
    color: var(--dark)
    line-height: 1.25

    em
      color: var(--brand)

.el-upload__tip
  position: relative
  color: var(--gray-dark)
  line-height: 1
  margin-top: 0
