.el-checkbox
  width: 100%
  color: var(--dark)
  font-weight: 400

  &__input
    height: 16px
    line-height: initial

    &.is-checked, &.is-focus, &.is-indeterminate
      .el-checkbox__inner
        background-color: transparent
        border-color: var(--brand)

        &:after
          border-color: var(--brand)

    &.is-checked
      & + .el-checkbox__label
        color: var(--dark)

    &.is-indeterminate
      .el-checkbox__inner
        &:before
          background-color: var(--brand)
          height: 4px
          top: 4px

          @media #{$M_XS}
            top: 3px    

  &__inner
    border: 2px solid var(--light)
    border-radius: var(--input-border-radius)
    width: 16px
    height: 16px

    @media #{$M_XS}
      width: 14px
      height: 14px

    &:after
      border-color: transparent
      border-width: 2px
      top: 1px
      left: 3px
      height: 6px

      @media #{$M_XS} 
        left: 2.5px
        height: 4px

  &:not(.is-disabled):hover
    .el-checkbox__inner
      border-color: var(--brand)

  &.is-checked:not(.is-disabled):focus-within
    .el-checkbox__inner
      border-color: var(--brand-dark-light)

      &:after 
        border-color: var(--brand-dark-light)

  &.is-disabled
    cursor: not-allowed

  @media #{$M_XS}
    &__label
      font-size: 12px

// Light theme
.light
  .el-checkbox
    color: var(--white)

    &__label
      color: var(--light)

    &__inner
      background-color: transparent
      border: 2px solid var(--gray-dark)
      border-radius: var(--input-border-radius)

      &:after
        border-color: var(--brand-dark)
      
    &__input
      &.is-focus
        .el-checkbox__inner
          border-color: var(--gray-dark)

      &.is-checked,
      &.is-indeterminate
        .el-checkbox__inner
          border-color: var(--white)
          background-color: var(--white)

        & + .el-checkbox__label
          color: var(--white)

      &.is-checked
        .el-checkbox__inner
          background-color: var(--white)

      &.is-indeterminate
        .el-checkbox__inner:before
          color: var(--white)

      &.is-disabled
        &  + .el-checkbox__label
          color: var(--gray)

        .el-checkbox__inner
          border-color: var(--gray)
          background-color: var(--gray)

          &:after
            border-color: var(--brand-dark)