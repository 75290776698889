body.body-wrap
  .tinybox
    &__content
      &__control
        background: no-repeat center/24px

        &--next, &--prev
          background-image: url("~@/static/arrow-line-thin.svg")

        &--next
          right: 60px

          @media #{$M_XS}
            right: 0

        &--prev
          transform: rotate(180deg)
          left: 60px

          @media #{$M_XS}
            left: 0

        &--close
          top: 10px
          right: 55px
          display: flex
          align-items: center
          justify-content: center
          width: 72px
          height: 72px
          transition: border-color var(--transition)
          background-image: none

          @media #{$M_XS}
            right: -5px

          &:hover
            border-color: var(--white)

          &:after
            content: ''
            mask-image: url("~@/static/cross-thin.svg")
            mask-size: contain
            background-color: var(--white)
            width: 24px
            height: 24px

      &__image
        @media #{$M_XS}
          max-width: 95%

        &__caption
          font: 700 14px/1 var(--font-family)
          bottom: -12px

    &__thumbs
      display: flex
      align-items: center
      justify-content: center

      &__item
        margin: 0 4px
        width: 56px
        height: 56px
        border-radius: 4px
        border: 2px solid transparent
        transition: border var(--transition)

        &--active
          opacity: 1
          border-color: var(--white)
