.el-popper.download-edit-report-popper
  &.el-dropdown-menu
    margin-top: 134px
    margin-bottom: 0
    max-height: calc(100vh - 154px)
    background: var(--brand-dark)

    &[x-placement^=bottom-start], &[x-placement^=bottom-end]
      margin-top: 12px

    .expand-button
      display: flex
      margin-left: calc(100% - 22px)
      transition: opacity var(--transition)

      &:hover
        opacity: .5

    .dimensions-wrapper,
    .metrics-wrapper
      max-height: 19vh

    .columns-wrapper
      max-height: 25vh

    &.is-expanded
      max-width: calc(100vw - 16px)
      width: auto

      display: flex
      flex-direction: column

      .search-field
        min-width: 130px

      .edit-table-columns-wrap
        display: flex
        overflow: hidden

        .edit-table-columns
          width: 100%
          display: flex
          flex-direction: column

      .dimensions-wrapper, .metrics-wrapper
        max-height: 100%

      .dimensions-wrapper
        .draggable-wrapper
          grid-template-columns: repeat(var(--expanded-config-table-column-number--dimensions, 1), minmax(200px, auto))

      .metrics-wrapper
        .draggable-wrapper
          grid-template-columns: repeat(var(--expanded-config-table-column-number--metrics, 1), minmax(200px, auto))

      .draggable-wrapper
        display: grid

      .el-dropdown-menu__item
        &.draggable:after
          position: absolute
          right: 8px


    &.retention-dropdown
      .dimensions-wrapper
        max-height: 36vh

    & .el-dropdown-menu__item
      display: flex
      padding: 0 16px
      line-height: 32px
      align-items: center
      position: relative

      .el-checkbox__label
        padding-left: 14px

      &:hover .el-checkbox:not(.is-disabled)
        .el-checkbox__label
          color: var(--white)

        .el-checkbox__inner
          border-color: var(--white)

      &.empty-list
        justify-content: center

        &:hover, &:focus-within
          background-color: transparent
          cursor: default

      &.subtitle
        color: var(--white)
        background-color: transparent!important
        cursor: default!important
        font-weight: bold
        padding: 8px 16px

        &:not(:first-child):not(.sub-option)
          padding-top: 12px
          border-top: 2px solid var(--brand-semi-light)

        &.sub-option
          padding: 0 16px

        span
          white-space: nowrap

      &.show-more-btn-item
        padding-top: 5px
        padding-bottom: 5px

      & .show-more-btn
        display: flex
        width: 100%
        height: 100%

        &-icon
          margin-left: 2px
          font-size: 12px
          stroke: transparent

        &-text
          font-size: 12px
          padding-left: 16px

          &:hover
            color: inherit

      &.draggable:hover:after
        content: ''
        background: url('~@/static/drag-indicator.svg')
        width: 24px
        height: 24px
        margin-left: auto

    .el-checkbox-group
      margin-bottom: 10px
      overflow: auto

    li.download-report
      padding: 0
      margin-top: 8px
      border-top: 2px solid var(--brand-semi-light)

      &:hover,
      &:focus-within
        background-color: transparent

      a
        padding: 0 16px
        margin-top: 8px
        font-weight: bold
        color: var(--white)
        width: 100%

    .search-field
      margin-left: 8px
      text-align: end

      .el-input__inner
        border-color: var(--gray-dark)
        line-height: 28px
        height: 28px
        max-width: 130px
        padding-right: 15px

        &::placeholder
          color: rgba(110, 122, 154, .5)

      .el-input__clear
        margin-right: 0

    .icons-wrapper
      margin-left: 3px

    .type-icon,
    .star-icon
      position: relative
      font-size: 12px
      color: var(--gray)
      vertical-align: -2px

      .disabled &
        color: inherit

    .star-icon
      top: -1px
      font-size: 16px

.download-report
  &.el-dropdown .el-dropdown-selfdefine:focus
    box-shadow: none

.el-button.edit-and-download-report
  border-left: 1px solid rgba(255, 255, 255, .5)
  position: relative
  z-index: 1
  min-width: auto
