@import "@/styles/common/_sass_variables.sass"
// SASS VARIABLES

$M_XS: #{'screen and (max-width: 825px)'} // phones
$M_SM: #{'screen and (max-width: 991px)'} // tablets
$M_MD: #{'screen and (max-width: 1199px)'} // medium desktop
$M_LG: #{'screen and (min-width: 1200px)'} // large desktop
.mag
  &-is-hidden
    display: none !important

  &-flex-nowrap
    flex-wrap: nowrap

  &-flex-wrap
    flex-wrap: wrap
// CSS VARIABLES
\:root
  // COLORS
  --black: #000000
  --white: #FFFFFF
  --white-dark: #F2F4F8
  --ultra-light: #E7E9EF
  --light: #C8CCD9
  --gray: #A0ABC4
  --gray-dark: #6E7A9A
  --gray-dark-light: #D7DAE3
  --gray-light: #F8FAFB
  --gray-ultra-light: #ECEFF3
  --dark: #0D122E
  --dark-transparent: rgba(0, 0, 0, 0.04)
  --brand: #3652F1
  --brand-transparent: #3652F1b5
  --brand-light: #809CFF
  --brand-light-transparent: #809CFFb5
  --brand-dark-light: #1A31B4
  --brand-semi-light: #222E67
  --brand-dark: #192354
  --brand-semi-dark: #18214E
  --brand-mega-dark: #151D45
  --brand-ultra-dark: #11183A
  --brand-ultra-dark-transparent: rgba(17, 24, 59, 0.5)
  --brand-shadow-color: rgba(54, 82, 241, 0.3)
  --red: #be4680
  --red-light: #DB81A7
  --android-green: #a4c639
  --green: #24AA8F
  --green-dark: #17823B
  --green-dark-transparent: rgb(23, 130, 59, 0.1)
  --red-lighter: #D25973
  --red-attention: #EB5757
  --disabled-background: #F5F7FA
  --disabled-text: var(--gray-dark)
  --disabled-border: #E4E7ED
  --funnels-current-visual: #3247A8
  --funnels-previous-visual: rgba(50, 71, 168, 0.5)

  // COMMON
  --input-border-radius: 4px
  --common-border-radius: 8px
  --button-border-radius: 4px
  --button-round-border-radius: 16px
  --transition: .3s ease
  --brand-color-box-shadow: 0px 2px 6px var(--brand-shadow-color)
  --dark-color-box-shadow: 0px 2px 6px rgba(13, 18, 46, 0.3)
  --light-color-box-shadow: 0px -3px 3px var(--dark-transparent), 0px 2px 4px var(--dark-transparent), 0px 2px 4px var(--dark-transparent), 0px 4px 8px var(--dark-transparent), 0px 8px 16px var(--dark-transparent)
  --dark-color-tooltip-box-shadow: 0px 1px 1px rgba(25, 35, 84, 0.06), 0px 2px 2px rgba(25, 35, 84, 0.06), 0px 4px 4px rgba(25, 35, 84, 0.06), 0px 8px 8px rgba(25, 35, 84, 0.06), 0px 16px 16px rgba(25, 35, 84, 0.06)
  --retention-table-cell-color-rgb: 50, 71, 168
  --dark-box-shadow: inset 0 0 2px 2px var(--brand-semi-light)
  --light-box-shadow: inset 0 0 2px 1px var(--gray-ultra-light)

  // FONT
  --font-family: 'Cera Pro', sans-serif
  --font-family-Arial-Mono: 'Arial Monospaced', sans-serif
  --font-size: 14px

  // CHARTS
  --axis-label-font-size: 12
  --axis-label-x-margin: 24
  --axis-label-y-margin: 18

@media #{$M_XS}
  \:root
    --font-size: 12px
    --axis-label-font-size: 10
    --axis-label-x-margin: 12
    --axis-label-y-margin: 12


// NEW STYLING
\:root
  // background colors
  --background-neutral-white: #FFFFFF
  --background-neutral-black: #0D122E
  --background-secondary-default: #AABBCF
  --background-neutral-white-hover: #DCE2FD
  --background-neutral-white-pressed: #C0CDFD
  --background-primary-soft-default: #DCE2FD
  --background-primary-soft-hover: #C0CDFD
  --background-primary-default: #3652F1
  --background-secondary-soft-default: #EBF0F4
  --background-secondary-soft-disabled: #F4F7F9
  --background-secondary-soft-hover: #DAE3EB
  --background-secondary-soft-pressed: #C3D1DE
  --background-success-default: #3EC372
  --background-success-soft-default: #E0F8E9
  --background-warning-default: #F0851F
  --background-warning-soft-default: #FDF0D7
  --background-error-soft-default: #FDE3E3
  --background-error-default: #EB5757
  --background-fuschsia-soft-default: #F7ECFB

  // text colors
  --text-primary-default: #3652F1
  --text-primary-disabled: #95AEFB
  --text-secondary-default: #6E7A9A
  --text-secondary-hover: #58627B
  --text-secondary-disabled: #AABBCF
  --text-secondary-pressed: #4B5364
  --text-neutral-white: #FFFFFF
  --text-neutral-black: #0D122E
  --text-neutral-disabled: #94A4C0
  --text-success-default: #3EC372
  --text-error-default: #EB5757
  --text-warning-default: #F0851F

  // icon colors
  --icon-primary-default: #3652F1
  --icon-neutral-primary: #181853
  --icon-secondary-default: #94A4C0
  --icon-secondary-hover: #6E7A9A
  --icon-secondary-light-defualt: #AABBCF
  --icon-success-default: #3EC372
  --icon-warning-default: #F0851F
  --icon-error-default: #EB5757
  --icon-fuschsia-default: #C062D3

  // border colors
  --border-neutral-light-default: #DAE3EB
  --border-neutral-light-disabled: #EBF0F4
  --border-neutral-light-hover: #C3D1DE
  --border-neutral-white-hover: #DCE2FD
  --border-neutral-white-pressed: #C0CDFD
  --border-primary-disabled: #C0CDFD
  --border-primary-default: #3652F1
  --border-error-default: #EB5757

  // transitions
  --transition-disolve: 0.150s ease-out

  // surface colors
  --surface-secondary-light: #F4F7F9

  // box shadows
  --shadow-level-1: 0 2px 4px 0 rgba(13, 18, 46, 0.16)
  --shadow-level-4: 0 4px 16px 0 rgba(13, 18, 46, 0.32)
  --shadow-level-5: 0 8px 24px 0 rgba(13, 18, 46, 0.32)


.main-menu
  .menu-button
    padding: 0 17px
    display: flex
    align-items: center
    justify-content: flex-start
    color: var(--white)
    font-weight: bold
    width: 225px
    height: 64px
    background-color: var(--brand-ultra-dark)

    @media #{$M_XS}
      width: auto
      height: 40px

    .title
      @media #{$M_XS}
        display: none

    .icon
      margin-right: 13px

      @media #{$M_XS}
        margin-right: 0
  li
    &.disabled
      a
        opacity: .3
        pointer-events: none
        cursor: default

  .router-link:focus
    color: var(--white)

  .close-button
    display: none
    align-items: center
    justify-content: center
    position: absolute
    top: 14px
    right: 16px
    width: 32px
    height: 32px
    background: var(--brand-dark)
    z-index: 1
    border-radius: 50%
    opacity: .85

    @media #{$M_XS}
      display: flex

.el-dropdown-menu
  width: 100vw
  left: 0 !important
  margin-top: 0 !important
  padding-top: 0
  padding-bottom: 0
  border-radius: 0

  @media #{$M_XS}
    top: 0!important

  &__item
    padding: 0

  &:before
    left: 125px

  .main-menu-dropdown
    display: flex
    overflow: auto
    padding: 0
    flex-direction: column

    &:hover
      background-color: inherit

  ul
    padding-left: 35px

    li
      position: relative

      &.active
        a
          svg
            color: var(--brand)

      &.disabled
        &:hover
          background: none !important

      a
        font-size: 16px
        line-height: 1.3
        padding: 0

        @media #{$M_XS}
          font-size: 12px

        &:hover
          color: currentColor
          background: none !important

  .menu-wrapper
    display: flex
    flex-direction: column
    background: var(--dark)
    margin-left: -100px
    padding-left: 100px
    padding-top: 10px
    height: 310px

    @media #{$M_XS}
      margin-left: 0
      padding-left: 0
      height: calc(100vh - 48px)

  .second-level
    display: flex
    height: 100%

    @media #{$M_XS}
      overflow: auto

    & > ul
      height: 100%
      width: 100%
      padding-top: 10px
      display: flex
      padding-left: 64px

      @media #{$M_XS}
        flex-direction: column
        padding: 10px 16px

      & > li
        max-width: 600px
        min-width: 250px
        margin-left: 20px
        display: flex
        flex-direction: column

        @media #{$M_XS}
          margin-left: 0

        &:first-child
          margin-left: 0

    .submenu-title
      display: flex
      align-items: center
      margin-bottom: 8px
      font-size: 18px
      font-weight: bold

      @media #{$M_XS}
        font-size: 16px
        margin-bottom: 0

      .icon
        color: var(--brand)
        stroke: none

    &.configurations-second-level .icon
      font-size: 18px

  ul.third-level
    margin: 10px 0 30px
    padding-left: 32px
    height: 100%
    overflow: auto

    li
      margin-top: 16px
      display: inline-block
      width: calc(50% - 20px)

      @media #{$M_XS}
        margin-top: 8px

      &:only-child
        width: 100%

      &:nth-child(even)
        margin-left: 40px

      a
        color: var(--light)

        &.disabled
          pointer-events: none
          opacity: .3
          cursor: default

        &:before
          content: ''
          opacity: 0
          transition: opacity var(--transition)
          width: 6px
          height: 9px
          display: block
          background: url('~@/static/arrow-line.svg')
          left: -26px
          top: 5px
          position: absolute

        &.router-link-active
          font-weight: bold
          color: var(--white)

        &:hover, &.router-link-active
          &:before
            opacity: 1

.el-tabs::v-deep
  .el-tabs__nav
    width: 100%

  .el-tabs__item:last-child
    opacity: 1
    position: absolute
    box-shadow: none
    pointer-events: auto
    right: 0
    margin-right: 4px

    @media #{$M_XS}
      position: relative
      padding: 0 5px!important
      margin-right: 0

    .el-input__inner
      color: var(--light)
      border: 1px solid var(--brand-semi-light)
      border-radius: 20px

      &::placeholder
        color: rgba(110, 122, 154, .5)

  .el-tabs__item
    &.is-active, &:hover
      .tab-title .icon
        color: var(--brand)

  .tab-title
    display: inline-flex

    .icon
      margin-right: 15px
      margin-top: -1px
      font-size: 16px
      transition: color var(--transition)
