div.vc-container
  background-color: var(--white)
  font-family: var(--font-family)
  font-style: normal
  font-weight: normal
  border-radius: var(--common-border-radius)
  border: 2px solid var(--ultra-light)
  color: var(--dark)
  height: auto

  // VARIABLES REWRITE
  --gray-200: var(--white-dark)
  --gray-900: var(--brand-dark)
  --blue-100: var(--brand-dark)
  --blue-200: var(--brand-light)
  --blue-600: var(--brand)
  --blue-700: var(--brand)
  --blue-900: var(--white)
  --rounded-full: 0

  .vc-pane-layout
    grid-template-columns: repeat(2, minmax(220px, 1fr))!important
    gap: 50px!important

    @media #{$M_XS}
      grid-template-columns: repeat(1, minmax(220px, 1fr))!important
      gap: 25px!important

  & .vc
    &-title
      color: var(--dark)
      font-size: var(--font-size)
      font-weight: bold

    &-arrows-container
      top: 1px
      padding-left: 0
      padding-right: 0

      div[role=button]
        position: relative
        padding: 5px

        &:first-child
          left: -5px

        &:last-child
          right: -5px

    &-weekday
      color: var(--gray-dark)
      font-weight: bold
      font-size: 12px
      padding-top: 10px
      padding-bottom: 15px
      width: 32px

    &-day
      min-height: 30px!important

    &-day-content
      background-color: transparent
      font-weight: normal!important

      @media #{$M_XS}
        font-size: 12px

      &.is-disabled
        color: var(--gray)
        pointer-events: none

        &:hover
          background-color: rgba(204,214,224,0.15)

    &-day-box-right-center,
    &-day-box-left-center
      & + .vc-day-layer .vc-highlight
        --white: var(--brand)
        //background-color: var(--brand)!important

    &-day-box-right-center + .vc-day-layer .vc-highlight
        border-radius: 4px 0 0 4px!important

    &-day-box-left-center + .vc-day-layer .vc-highlight
      border-radius: 0 4px 4px 0!important

    &-highlights ~ .vc-day-box-center-bottom > .vc-bars
      display: none

    &-svg-icon
      width: 15px!important
      height: 15px!important

    &-future-day
      --blue-200: var(--red-light)
      --blue-600: var(--red)

      .vc-highlight
        border-color: transparent!important

      .vc-day-box-right-center,
      .vc-day-box-left-center
        & + .vc-day-layer .vc-highlight
          --white: var(--red)

      .vc-day-content:only-child:not(.is-disabled)
        color: var(--red)!important

      &.vc-day
        .vc-day-layer:only-child
          .vc-highlight:not(.vc-highlight-base-middle)
            background-color: var(--red)!important

    &-day.on-right .vc-highlight.vc-highlight-base-middle
      position: relative
      right: 4px

    &-day.on-left .vc-highlight.vc-highlight-base-middle
      position: relative
      left: 4px

  &:not(.chronology-date-picker)
    .vc-day .vc-day-layer:only-child .vc-highlight:only-child:not(.vc-highlight-base-middle)
      background-color: var(--brand)!important
      border-radius: 4px!important
      border-width: 0!important

.vc-popover-content
  background-color: var(--brand-semi-light)

.datepicker-wrapper,
.vc-popover-content-wrapper.is-interactive
  & > .vc-container
    padding: 10px 30px

    @media #{$M_XS}
      padding: 10px 15px

.vc-nav-title
  font-weight: normal
  color: var(--white)

.vc-nav-item
  &.is-disabled
    opacity: 1
    color: var(--light)
    font-weight: 400

  &.is-current
    color: var(--white)
    border-color: var(--brand)

