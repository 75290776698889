.el-color-dropdown
  &__btns
    display: flex
    justify-content: flex-end
    align-items: center

    .el-button
      min-width: auto
      font-weight: normal

  &__value
    margin-right: auto

.el-color-picker
  &__empty
    &:before
      content: ''
      mask-image: url("~@/static/paint-bucket.svg")
      mask-size: cover
      background-color: #999
      width: 20px
      height: 20px
      display: block
