body
  background: var(--white-dark)
  font-family: var(--font-family)
  font-size: var(--font-size)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

  :has(.auth-layout)
    background: var(--white)
    min-height: 100vh

*:focus
  outline: none

.content-wrapper
  padding-top: 128px

  &.no-filters
    padding-top: 64px

  &.limited-width
    max-width: 1216px
    margin: 0 auto
    padding-left: 24px
    padding-right: 24px

[role=button]
  cursor: pointer

input::placeholder
  font-family: var(--font-family)
  font-size: var(--font-size)

h1, h2, h3, h4, h5, h6
  font-weight: bold

h1
  font-size: 20px

h2
  font-size: 18px

h3
  font-size: 16px

h4
  font-size: 14px

h5
  font-size: 14px

h6
  font-size: 10px

a
  transition: color var(--transition)

  &:hover,
  &:focus
    color: var(--brand)

  &.brand-link
    color: var(--brand)

    &:hover,
    &:focus
      text-decoration: underline

.wrapped-date
  display: inline-flex

.page-title
  font-size: 16px

.plus-icon
  &:before
    content: "+"
    color: var(--brand)
    width: 24px
    height: 24px
    border-radius: 50%
    background-color: var(--white-dark)
    display: flex
    align-items: center
    flex-shrink: 0
    justify-content: center
    font-size: 20px
    font-weight: bold
    line-height: 1
    transition: color var(--transition), background-color var(--transition)
    margin-right: 4px

  &:hover
    &:before
      color: var(--white-dark)
      background-color: var(--brand)

  &.inverted
    &:before
      color: var(--white-dark)
      background-color: var(--brand)

    &:hover
      &:before
        color: var(--brand)
        background-color: var(--white-dark)
