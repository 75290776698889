.fade-enter-active, .fade-leave-active {
    transition: opacity .3s
}

.fade-enter, .fade-leave-active, .fade-leave-to {
    opacity: 0
}

.jump-enter-active {
    animation: jump .2s
}

.jump-leave-active {
    animation: jump .2s reverse
}

@keyframes jump {
    0% {
        transform: translateY(10px)
    }
    50% {
        transform: translateY(-10px)
    }
    100% {
        transform: translateY(0)
    }
}

.long-form-enter-active {
    transition: all .2s ease
}

.long-form-leave-active {
    transition: all .5s cubic-bezier(1, .5, .8, 1)
}

.long-form-enter, .long-form-leave-to {
    border-right: solid 1px #ddd !important;
    opacity: 0;
    transform: scaleX(0)
}

.scale-up-enter-active {
    animation: scaleUp .2s
}

.scale-up-leave-active {
    animation: scaleUp .2s reverse
}

@keyframes scaleUp {
    0% {
        transform: scale3D(1, 1, 1)
    }
    50% {
        transform: scale3D(1.2, 1.2, 1.2)
    }
    100% {
        transform: scale3D(1, 1, 1)
    }
}

.scale-down-enter-active {
    animation: scaleDown .2s
}

.scale-down-leave-active {
    animation: scaleDown .2s reverse
}

@keyframes scaleDown {
    0% {
        transform: scale3D(1.2, 1.2, 1.2)
    }
    50% {
        transform: scale3D(.8, .8, .8)
    }
    100% {
        transform: scale3D(1, 1, 1)
    }
}

.slide-left-enter-active {
    animation: slideLeft .2s
}

.slide-left-leave-active {
    animation: slideLeft .2s reverse
}

@keyframes slideLeft {
    0% {
        transform: translate3d(30px, 0, 0)
    }
    70% {
        transform: translate3d(-5px, 0, 0)
    }
    100% {
        transform: translate3d(0, 0, 0)
    }
}

.slide-right-enter-active {
    animation: slideRight .2s
}

.slide-right-leave-active {
    animation: slideRight .2s reverse
}

@keyframes slideRight {
    0% {
        transform: translate3d(-30px, 0, 0)
    }
    70% {
        transform: translate3d(5px, 0, 0)
    }
    100% {
        transform: translate3d(0, 0, 0)
    }
}
