.el-radio-button
  &:first-child
    .el-radio-button__inner
      border-radius: 4px 0 0 4px
      border-left-width: 2px

  &__orig-radio
    &:checked
      & + .el-radio-button__inner
        background-color: var(--background-primary-soft-default)
        border-color: var(--border-primary-default)
        transition: background-color var(--transition-disolve)
        color: var(--text-primary-default)
        box-shadow: none

        .postfix
          color: var(--text-primary-default)

        .icon
          background-color: var(--icon-primary-default)

      &:disabled
        & + .el-radio-button__inner
          background-color: var(--light)
          color: var(--gray-dark)
          box-shadow: none!important

    &:disabled
      & + .el-radio-button__inner
        background-color: var(--white-dark)
        color: var(--gray)

  &__inner
    display: flex
    border: 2px solid var(--border-neutral-light-default)
    color: var(--text-neutral-black)
    font-size: 14px
    line-height: 1.71
    font-weight: 400
    padding: 4px 12px
    align-items: center

    &:hover
      color: var(--text-primary-default)

    .postfix
      color: var(--text-neutral-black)
      font-weight: 500
      margin-left: 4px

  .icon
    width: 20px
    height: 20px
    mask-size: contain
    mask-repeat: no-repeat
    mask-position: center
    background-color: var(--icon-secondary-default)
    margin-right: 4px

  &:focus:not(.is-focus):not(:active):not(.is-disabled)
    box-shadow: var(--brand-color-box-shadow)

  // small styles
  &--small
    .el-radio-button__inner
      padding: 8px
