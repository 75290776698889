.el-tag
  font-size: var(--font-size)
  font-weight: bold
  white-space: normal

  &--small
    height: auto

  &.el-tag--info
    background-color: var(--white)
    border: 1px solid var(--ultra-light)
    color: var(--dark)

    .el-tag__close
      background-color: var(--ultra-light)
      color: var(--dark)

      &:hover
        background-color: var(--gray-dark)
        color: var(--ultra-light)

  .no-first-tag-remove &:first-of-type
      .el-tag__close
        display: none

  .tag-link &
    &.el-tag--info
      color: var(--brand)
      cursor: pointer

      .el-select__tags-text:hover
        text-decoration: underline

  .collapse-tags &:last-child
    .el-select__tags-text:hover
      cursor: pointer
      text-decoration: underline

  .collapse-tags.tag-link &
    &.el-tag--info:last-child
      color: var(--dark)
