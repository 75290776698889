.category-card
  width: 100%
  background: var(--white)
  border-radius: var(--common-border-radius)
  border: 1px solid var(--ultra-light)

  .card-header
    position: relative
    display: flex
    flex-wrap: wrap

    .card-title
      color: var(--dark)
      font-size: 16px
      margin-right: 10px

    .edit-btn
      position: relative
      color: var(--gray)
      font-size: 14px
      top: 2px

      .svg-icon
        stroke: none
        font-size: 15px

      &:hover,
      &:focus
        color: var(--brand)

  .content-list
    display: flex
    flex-wrap: wrap
    gap: 7px
    position: relative
    margin-top: 20px
    margin-bottom: 7px

    &.inline
      max-height: 207px
      flex-direction: column
      overflow-x: scroll
      overflow-y: hidden

  .scrollable-shadow
    &.right-shadow:after
      height: 207px
      margin-left: -27px

    &.left-shadow:before
      height: 207px
      margin-right: -27px