.el-select
  &:hover
    .el-input__inner
      border-color: var(--ultra-light)

  &.dark
    &__inner
      background: var(--white-dark)

    .el-input:hover .el-input__inner,
    .el-input.is-focus .el-input__inner,
    .el-input .el-input__inner
      border-color: var(--brand-semi-light)

    .el-input .el-input__inner
      &.is-focus, &:focus
        border-color: var(--brand-semi-light)
        box-shadow: var(--dark-box-shadow)

        & + .el-input__suffix
          .el-select__caret
            color: var(--gray)

    .el-select__caret
      color: var(--gray)

  .el-input
    &__inner
      background: var(--white)
      border: 2px solid var(--ultra-light)
      border-radius: var(--input-border-radius)
      font-weight: bold
      font-family: var(--font-family)
      font-size: var(--font-size)

      &:focus + .el-input__suffix
        .el-select__caret
          color: var(--dark)

    &__icon
      line-height: 32px

    .el-select__caret
      color: var(--gray)

  &.white-dark
    .el-input
      &.is-focus
        .el-input__inner
          border: 2px solid var(--white-dark)

      &__inner
        border: 2px solid var(--white-dark)

  .el-input
    .el-input__inner
      &:focus
        border-color: var(--ultra-light)

    &.is-focus,
    &:hover
      .el-input__inner
        border-color: var(--ultra-light)

    &__prefix, &__suffix, &__suffix-inner
      pointer-events: none

  &.no-border
    .el-input
      &__inner
        border: none
