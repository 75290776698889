.el-button.settings-button
  position: fixed
  right: 32px
  bottom: 32px
  z-index: 50
  padding: 8px
  min-width: auto
  line-height: 0
  background-color: var(--white-dark)
  color: var(--brand)
  box-shadow: var(--light-color-box-shadow)

  @media #{$M_XS}
    right: 12px
    bottom: 24px

  &:hover,
  &:focus
    color: var(--white)
    background-color: var(--brand)

  .settings-icon
    position: relative
    top: 0
