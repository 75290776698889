.el-notification
  align-items: center
  width: auto
  max-width: 500px

  .el-icon-info
    color: var(--brand)

  &__group
    display: flex
    align-items: center

  &__closeBtn
    top: auto

  &__content
    margin: 4px 0 0 0

  .refresh-link,
  .notification-link
    text-decoration: underline
    color: var(--brand)
    font-weight: 500
    padding-right: 15px

    &:hover
      text-decoration: none