.el-switch
  &.el-switch-v2
    .el-switch__label
      font-size: 16px
      color: var(--text-neutral-black)

    .el-switch__core
      height: 16px
      background-color: var(--background-secondary-soft-default)
      border: none

      &:after
        background-color: var(--background-secondary-default)
        top: 2px
        left: 3px
        width: 12px
        height: 12px

    &.is-checked
      .el-switch__core
        border: none
        background-color: var(--background-primary-soft-default)

        &:after
          background-color: var(--background-primary-default)
          left: 107%
