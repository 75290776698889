.main
  border-radius: var(--common-border-radius)
  background: var(--white)
  margin: 24px
  padding: 24px
  position: relative

  &:empty
    margin: 0
    padding: 0

  @media #{$M_XS}
    padding: 12px
    margin: 12px

  &.empty
    display: flex
    padding: 0
    min-height: 48px

  &.transparent
    background: transparent
    padding: 24px 0
    margin: 0 24px

    @media #{$M_XS}
      padding: 0
      margin: 12px

.table-section-wrapper
  margin: 32px 24px 16px
  position: relative
  z-index: 0

  @media #{$M_XS}
    margin: 24px 12px 12px

.no-data
  display: inline-flex
  align-items: center
  justify-content: center
  width: 100%
  font-size: var(--font-size)
  color: var(--dark)

.positive
  color: var(--text-success-default)

.negative
  color: var(--text-error-default)

.neutral
  color: var(--gray-dark)

.back
  display: inline-flex
  align-items: center
  color: var(--gray-dark)

  .icon-wrap
    content: ''
    width: 24px
    height: 24px
    background-color: var(--ultra-light)
    display: inline-flex
    align-items: center
    justify-content: center
    border-radius: 50%
    margin-right: 8px
    color: var(--brand)
    font-size: 8px
    transform: rotate(180deg)
    transition: color var(--transition), background-color var(--transition)

  &:hover,
  &:focus
    color: var(--gray-dark)

    .icon-wrap
      color: var(--white)
      background-color: var(--brand)

.diff-value
  display: flex
  align-items: center
  gap: 4px
  line-height: 20px

  &:not(.positive):not(.negative)
    color: var(--text-secondary-default)

  &.positive,
  &.negative
    &:after
      content: ''
      width: 0
      height: 0
      border-style: solid
      border-width: 0 4px 6px 4px
      border-color: transparent transparent currentColor transparent

  &.negative
    &:after
      transform: rotate(180deg)
