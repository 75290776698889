.list
  &.grey-bg
    margin-bottom: 16px

    li
      color: var(--dark)
      background-color: var(--gray-light)
      padding: 8px 26px
      display: flex
      align-items: center
      border-radius: var(--input-border-radius)

      & + li
        margin-top: 8px

      .image-preview
        display: flex
        margin-right: 16px

        img, video
          width: 75px
          height: 75px
          object-fit: cover
          border-radius: var(--common-border-radius)
          border: 2px solid transparent
          transition: border-color var(--transition)
          cursor: pointer

          &:hover, &:focus
            border-color: var(--brand)

      .title, .info, .remove
        line-height: 1

      .title
        font-size: var(--font-size)
        font-weight: bold

      .info
        color: var(--gray-dark)
        padding-left: 16px
        margin-left: auto

      .remove
        margin-left: 24px
        color: var(--gray-dark)

        &:hover,
        &:focus
          color: var(--brand)
