.el-time-panel
  border: 2px solid var(--ultra-light)
  border-radius: var(--common-border-radius)
  font-family: var(--font-family)
  box-shadow: none

  &__footer
    padding: 6px 4px
    height: 40px
    border-top: 2px solid var(--white-dark)

  &__btn
    line-height: 21px
    font-weight: 700
    font-size: var(--font-size)
    font-family: var(--font-family)
    border-radius: var(--button-round-border-radius)
    transition-property: color,background
    transition-duration: .3s

    &.cancel
      color: var(--brand)

      &:hover,
      &:focus
        color: var(--brand-dark-light)

    &.confirm
      background: var(--brand)
      color: var(--white)
      padding: 0px 15px
      font-weight: 700

      &:hover,
      &:focus
        background: var(--brand-dark-light)

.el-time-spinner
  &__item
    font-size: var(--font-size)
    color: var(--dark)
    font-style: normal
    font-weight: normal

    &.active:not(.disabled)
      color: var(--dark)
