.el-dropdown-menu
  right: 0
  position: absolute
  margin: 5px 0
  z-index: 10
  padding: 8px 0
  color: var(--white)
  background-color: transparent
  border: none
  box-shadow: none

  //HEADER STYLES
  &.header
    li
      a
        padding: 12px 16px
        display: flex
        align-items: center
        transition: background var(--transition)

      svg
        margin-right: 15px
        margin-top: -1px
        font-size: 16px
        transition: color var(--transition)

  //BASIC DROPDOWN WHITE
  &.basic-dropdown
    background: var(--white)
    border-radius: var(--common-border-radius)
    border: 2px solid var(--ultra-light)
    padding: 8px 0
    width: 300px
    max-height: 600px
    overflow: auto
    right: auto
    left: 0

    @media #{$M_XS}
      width: 250px
      font-size: 12px

    &.is-pinned
      max-height: 700px

    &, &.is-pinned
      @media (max-height: 770px)
        max-height: 80vh

    ul.pinned-items
      border-bottom: 2px solid var(--white-dark)

    li.pinned-items-header
      line-height: 40px
      font-weight: 700
      padding-left: 24px

      @media #{$M_XS}
        line-height: 30px

    .pinned-items-scrollable
      max-height: 222px
      overflow: auto
      overflow-x: hidden

      @media (max-height: 770px)
        max-height: 15vh

    li
      color: var(--dark)
      font-size: var(--font-size)
      padding: 0 32px
      line-height: 32px
      cursor: pointer

      @media #{$M_XS}
        line-height: 28px

      &:hover,
      &.el-dropdown-menu__item:focus-within
        color: var(--dark)
        background-color: var(--white-dark)

      &.search-input
        margin: 4px 16px 16px
        padding: 0
        position: relative

        &:after
          content: ''
          width: calc(#{'100% + 32px'})
          height: 2px
          position: absolute
          bottom: -16px
          left: -16px
          background-color: var(--white-dark)

      &.disabled
        cursor: not-allowed

        &:hover
          background-color: transparent

        label
          cursor: not-allowed

      &.actions
        display: flex
        padding: 15px 32px
        align-items: flex-end
        justify-content: flex-end

      &.search-items
        padding: 0

        .search-items-scrollable
          display: block
          width: 100%
          overflow: auto
          overflow-x: hidden
          max-height: 220px

          @media (max-height: 770px)
            max-height: 20vh

      &.no-matching-data
        text-align: center
        cursor: default
        color: var(--light)

      &.no-matching-data,
      &.search-items,
      &.search-input,
      &.pinned-items-header,
      &.footer,
      &.actions
        cursor: auto

        &:focus-within,
        &:hover
          background-color: transparent

  // restyling for basic-dropdown
  &.basic-dropdown-v2
    padding: 20px 0
    box-shadow: 0 4px 16px 0 rgba(13, 18, 46, 0.32)
    border: none

    li
      &:hover
        background: var(--background-secondary-soft-hover, #DAE3EB)
        color: var(--text-neutral-black)

    & > li
      padding: 0 20px
      display: flex
      flex-direction: column
      gap: 20px
      cursor: default

      &:hover, &:focus-within
        background: none

    .dropdown-title
      font-size: 20px
      line-height: 32px
      font-weight: 700
      color: var(--text-neutral-black)

    .el-checkbox
      &__input
        &.is-disabled
          + span
            &.el-checkbox
              &__label
                color: var(--text-neutral-disabled)

          &.is-checked
            .el-checkbox
              &__inner
                background-color: transparent
                border-color: var(--border-primary-disabled)

                &:after
                  border-color: var(--border-primary-disabled)

    .el-checkbox, .el-radio
      gap: 8px

      .el-checkbox__label
        font-size: 16px
        line-height: 24px
        padding-right: 0

  &.metrics-dropdown
    transform-origin: center top!important
    max-height: 300px

    .el-dropdown-menu__item
      display: flex
      padding: 0
      min-height: 35px
      line-height: normal

      .legend-option
        position: relative
        width: 100%
        padding: 0 20px
        font-size: 12px
        font-weight: 500
        color: var(--brand)
        display: inline-flex
        align-items: center
        word-break: break-word

        .legend-icon
          display: inline-flex
          min-width: 14px
          min-height: 14px
          border-radius: 50%
          margin-right: 15px
          transition: background-color var(--transition)

        &.disabled
          color: var(--gray)

          .legend-icon
            border: 2px solid var(--gray)
            background-color: var(--white)!important

    .legend-visibility-toggle
      color: var(--gray-dark)
      display: flex
      align-items: center
      white-space: nowrap
      width: 100%
      padding: 0 20px
      font-size: 12px
      border-bottom: 2px solid var(--white-dark)

      &-icon
        padding-right: 10px
        flex-shrink: 0
        flex-basis: 24px
        margin-right: 5px

    .legend-name,
    .visibility-toggle-name
      position: relative
      top: 1px
      padding-top: 10px
      padding-bottom: 10px

  //DARK THEME
  &.dark
    border: none
    background: var(--brand-ultra-dark)

    .el-dropdown-menu__item:focus
      background-color: var(--dark)

    &:before
      border-color: transparent transparent var(--brand-ultra-dark) transparent

    & .el-dropdown-menu__item
      color: var(--white)

    li
      &:hover,
      &.el-dropdown-menu__item:focus-within
        color: var(--white)
        background-color: var(--dark)

      a
        &:hover
          background: var(--dark)

          svg
            color: var(--brand)

  .spinner
    margin-top: 20px

  .icons
    display: inline-flex
    align-items: center
    position: relative
    top: -1px

    &.adding-gap
      min-width: 40px

      @media #{$M_XS}
        min-width: 30px

      &:empty
        min-height: 16px

  .star-icon
    display: inline-flex
    font-size: 16px
    color: var(--brand)
    margin-right: 24px

  .pinnable
    cursor: pointer

  .el-checkbox, .el-radio
    width: 100%
    padding: 6px 0
    display: flex
    align-items: center
    user-select: auto

    &__input
      display: inline-flex

    &__label
      margin-right: auto
      padding-left: 0
      padding-right: 8px
      white-space: normal
      word-break: break-word
      display: inline-flex
      align-items: center

      .icons-wrapper
        display: inline-flex
        align-items: center
        padding-right: 20px

        @media #{$M_XS}
          padding-right: 15px

          .logo
            margin-right: 0

        .icon
          margin-top: 0
          margin-right: 0

      .icon-wrapper:before
        right: -3px
        top: -2px

// Filters dropdown
.filter-wrapper
  .el-dropdown-menu
    .el-checkbox, .el-radio
      flex-direction: row-reverse
      min-height: 40px
      padding: 10px 0

      @media #{$M_XS}
        min-height: 34px
        padding: 8px 0

    .numeric-wrapper
      .separator
        top: 12px

    .include-exclude-radio
      display: flex

      .el-radio
        flex-direction: row

        .el-radio__input
          margin-right: 8px

