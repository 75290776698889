@mixin deep-level-generator
  @for $i from 1 through 12
    .el-table__row--level-#{$i} .dimension-column .cell
      padding-left: 10px * $i

.el-table
  &__fixed-body-wrapper,
  &__fixed-header-wrapper
    z-index: 2

  @include deep-level-generator
  font-size: var(--font-size)
  border-radius: var(--common-border-radius)
  color: var(--dark)

  &:before
    display: none

  &.small
    font-size: 10px

  &.medium
    font-size: 12px

  &.large
    font-size: 14px

  &.no-fixed-right-shadow
    .el-table__fixed-right
      box-shadow: none

      .el-table__fixed-header-wrapper
        display: none

      tr
        background: transparent

        &.hover-row
          td
            opacity: 1

        td
          opacity: 0

  &__fixed-right::before
    display: none

  &.fake-right-shadow-table
    .el-table__fixed-right
      width: 0 !important

    .cell
      text-align: right
      justify-content: flex-end

    .el-table__footer-wrapper
      tbody
        td
          box-shadow: inset 2px 0 0 0 var(--gray-dark-light)

  .cell
    line-height: 1.2
    padding-left: 0
    padding-right: 0
    word-break: break-word
    display: inline-flex
    align-items: center
    width: 100%

    a
      color: var(--brand)

      &:hover
        text-decoration: underline

  .el-table__footer .cell
    padding-right: 2px

  td.metric-column
    text-align: right

    .cell
      white-space: nowrap
      width: fit-content

  .metric-column,
  .dimension-column
    padding-left: 16px
    padding-right: 16px

    @media #{$M_XS}
      padding-left: 12px
      padding-right: 12px

  &__body
    font-family: Arial, sans-serif
    font-size: var(--font-size)

    tr.hover-row > td
      background-color: var(--gray-light)

      .el-table__expand-icon
        color: var(--brand)

  &__fixed
    border-right: 2px solid var(--light)
    z-index: 2

    &:before
      display: none

    &-right
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.32)

  &__fixed,
  .dimension-column

    .cell
      justify-content: flex-end
      flex-direction: row-reverse
      text-align: left


  thead
    color: var(--dark)
    font-weight: bold

  th
    padding: 14px 0

    @media #{$M_XS}
      padding: 8px 0 8px

    .cell
      text-align: left
      display: flex
      justify-content: flex-start
      flex-direction: row
      padding-left: 0
      padding-right: 0

      .caret-wrapper
        margin-left: auto
        width: 16px

    &.is-leaf
      border-bottom: 2px solid var(--white-dark)

  td
    padding: 11px 0

    @media #{$M_XS}
      padding: 8px 0

  td, th
    border-bottom: 2px solid var(--white-dark)

    &.dimension-column
      &.last-dimension-column
        border-right: 2px solid var(--light)

        & + td, & + th
          &:not(.dimension-column)
            box-shadow: none

    &:first-child,
    &.is-hidden + :not(.is-hidden)
      box-shadow: none

  &__footer-wrapper,
  &__fixed-footer-wrapper
    tbody td
      background-color: var(--ultra-light)
      color: var(--dark)
      box-shadow: none


  &__footer-wrapper
    tbody td
      border-top: none

    .cell
      font-family: var(--font-family-Arial-Mono)
      font-weight: bold

  &__fixed-footer-wrapper
    .cell
      font-weight: bold
      color: var(--gray-dark)

  tr[class*='el-table__row--level-']:not(.el-table__row--level-0)
    background-color: var(--white-dark)

    & > .dimension-column
      border-right: 2px solid var(--light)

      & + td
        box-shadow: none

    &:nth-child(2n+1)
      background-color: var(--gray-light)

    td
      position: relative

    &.hover-row
      td
        background-color: var(--gray-ultra-light)

    td
      box-shadow: inset 2px 0 0 0 var(--ultra-light)
      border-bottom-color: var(--ultra-light)
      color: var(--gray-dark)

      &:first-child,
      &.is-hidden + :not(.is-hidden)
        box-shadow: none

      .el-table__indent,
      .el-table__placeholder
        display: none

  .el-table__row .el-table__expand-icon
    width: 12px
    display: flex
    position: static
    padding-left: 0
    margin-left: auto
    margin-right: 0
    transform: none
    justify-content: center
    align-items: center

    &:before
      content: ''
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      bottom: 0
      right: 0

    &:not(.el-table__expand-icon--expanded)
      margin-right: 1px

    i
      transform: rotate(90deg)
      transition: transform 0.2s ease-in-out
      display: flex
      justify-content: center

    &--expanded
      i
        transform: rotate(270deg)

  .caret-wrapper
    height: 16px
    width: 16px

  th .cell .sort-wrapper .caret-wrapper
    width: 14px

  tr.el-table__row--level-0
    &.hover-row + tr.el-table__row--level-1
      td
        &:before
          background-color: var(--gray-light)

  tr.el-table__row--level-0 + tr.el-table__row--level-1
    td
      border-top: 2px solid var(--ultra-light)

      &:before
        content: ''
        width: calc(100% - 2px)
        height: 2px
        background-color: #fff
        position: absolute
        top: -4px
        left: 2px


  .colored
    .sort-caret
      &.ascending
        border-bottom-color: currentColor

      &.descending
        border-top-color: currentColor

  .sort-caret
    left: 3px

    &.ascending
      border-bottom-color: var(--icon-secondary-default)
      top: -4px

    &.descending
      border-top-color: var(--icon-secondary-default)
      bottom: -2px

  .ascending
    .sort-caret.ascending
      border-bottom-color: var(--brand)

  .descending
    .sort-caret.descending
      border-top-color: var(--brand)

  &--medium
    font-size: 12px

    .cell
      font-size: 12px

    .metric-column,
    .dimension-column
      padding-left: 8px
      padding-right: 8px

    &__body
      font-size: 12px

    th
      padding: 10px 0 8px

    td
      padding: 6px 0

  &--small
    font-size: 11px

    .cell
      font-size: 11px

    .metric-column,
    .dimension-column
      padding-left: 4px
      padding-right: 4px

    &__body
      font-size: 11px

    th
      padding: 5px 0 4px

    td
      padding: 3px 0

  &-column--selection
    .el-checkbox
      display: flex
      justify-content: center

    .cell,
    .el-checkbox__input
      display: flex

  // BORDERED TABLE STYLES
  &--border
    border: none

    td, th
      border-right: none
      box-shadow: inset 2px 0 0 0 var(--white-dark)

      &:first-child
        .cell
          padding-left: 0

      &.is-leaf
        border-bottom: 2px solid var(--light)

// Transparent table
.el-table
  &.table-transparent
    tr
      &:hover
        & > td
          background-color: transparent

// additional table elements
.table-configurations
  padding-bottom: 16px
  display: flex

  @media #{$M_XS}
    flex-direction: column

  .left-column
    display: flex

    @media #{$M_XS}
      align-items: flex-end

    > *:not(:first-child)
      margin-left: 16px

      @media #{$M_XS}
        margin-left: auto

  .right-column
    margin-left: auto
    display: flex
    align-items: center

    @media #{$M_XS}
      margin-left: 0
      margin-top: 16px

// Custom table
.simple-table-wrapper
  &.shadows-wrapper
    position: relative

    .left-shadow, &:after
      content: ''
      position: absolute
      display: block
      width: 10px
      height: 100%
      top: 0
      z-index: 3
      transition: opacity var(--transition)
      opacity: 0
      pointer-events: none

    &.shadow-right
      &:after
        opacity: 1
        right: 0
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, .12) 100%)

    &.shadow-left
      .left-shadow
        opacity: 1
        background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, .12) 100%)

  &.scrolled-dimension-columns
    .simple-table
      .dimension-column, .dimensions-header-wrap
        position: static!important

  .table-wrapper
    position: relative
    overflow: auto
    width: 100%

  .simple-table
    table-layout: fixed
    font-family: Arial,sans-serif

    &.el-table
      position: initial
      overflow: auto

    &:not(.retention-table)
      thead
        display: inline-flex

        th
          display: flex


        tr
          display: flex

        .dimensions-header-wrap
          position: sticky
          left: 0
          z-index: 1

    thead, tfoot
      color: var(--dark)
      font-weight: 700
      position: sticky
      top: 0
      z-index: 2

      tr:first-child
        th
          border-bottom: 2px solid var(--white-dark)

      th
        padding: 8px
        text-align: left
        border-bottom-color: var(--white-dark)
        background-color: var(--white)

        &.thead-metrics-cell
          text-align: center
          border-right: 2px solid #ebeef5
          position: relative
          z-index: 0


        .header-wrap
          padding: 4px 0

    tfoot
      top: auto
      bottom: -1px

    td, th
      padding: 0 8px

    td
      padding: 8px
      border-bottom: 2px solid var(--white)

      &.metrics
        padding: 0
        border-right: 2px solid #ebeef5
        border-bottom: 2px solid #ebeef5
        z-index: 0
        position: relative
        height: 1px
        vertical-align: top

      .el-table__expand-icon
        display: flex
        align-items: center
        margin-left: auto

    tr
      background-color: transparent

    .children
      .metrics
        box-shadow: none
      .dimension-column
        border-bottom: 2px solid #ebeef5

      tr, .dimension-column
        background-color: var(--white-dark)
      &:nth-child(odd)
        tr, .dimension-column
          background-color: var(--gray-light)

    .dimension-column
      position: sticky
      background: var(--white)
      z-index: 1
      vertical-align: middle
      border-bottom: 2px solid var(--white-dark)
      box-shadow: inset 2px 0 0 0 var(--white-dark)

      &.last-dimension-column
        border-right: 2px solid var(--light)

        & + td, & + th
          box-shadow: none

      .cell
        display: inline-flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        padding: 0

        .icons-wrapper
          padding-right: 6px

        .cell-value
          display: flex
          flex-direction: column

    .cell
      display: flex

    .white-text
      .cell-value
        color: var(--white)

    .cell-value
      word-break: break-word

      a
        color: var(--brand)

        &:hover,
        &:focus
          text-decoration: underline

    .cell-content
      display: flex
      justify-content: center

    .total-row
      background-color: var(--gray-ultra-light)
      padding: 8px

      .total-cell-metrics
        font-family: var(--font-family-Arial-Mono)
        font-weight: 700
        text-align: right
        box-shadow: none
        border-bottom: none
        border-right: 2px solid var(--dark-transparent)

      .dimension-column
        background-color: var(--gray-ultra-light)
        font-weight: normal

// Redesigned table
.redesigned-table-wrap
  .el-table
    border-radius: 8px
    border: 1px solid var(--border-neutral-light-default)
    border-bottom: none

    &__body
      font-size: 13px
      font-family: var(--font-family)

    &__fixed-footer-wrapper
      tr
        background-color: transparent
      tbody
        td
          &.el-table__cell
            border-top: none
            border-bottom: none!important
            background-color: transparent

    th
      &.el-table__cell
        background-color: var(--surface-secondary-light)
        font-size: 12px
        border-bottom: 1px solid var(--border-neutral-light-default)
        padding: 0

    td
      &.el-table__cell,
      &.el-table__cell.is-leaf
        border-bottom: 1px solid var(--border-neutral-light-default)

    .el-table__cell
      padding: 8px 0

    &__header
      .header-wrap
        padding: 13px 16px 12px

    &__footer-wrapper
      .total-cell-name
        font: 700 14px/24px var(--font-family)
        color: var(--text-neutral-black)

      tbody
        tr, td.el-table__cell
          background: var(--surface-secondary-light)

        td
          &.el-table__cell
            border-top-color: var(--border-neutral-light-default)

          .cell
            color: var(--text-neutral-black)
            font-family: var(--font-family-Arial-Mono)
            font-weight: normal
