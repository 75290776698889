.el-input-number
  width: 100%
  line-height: 32px

  &.el-input-number--mini
    line-height: 26px
    height: 28px

    &.is-controls-right[class*=mini] [class*=decrease],
    &.is-controls-right[class*=mini] [class*=increase]
      line-height: 11px
