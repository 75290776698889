.el-skeleton
  &.is-animated
    .el-skeleton__item
      background: linear-gradient(90deg, #DAE3EB 25%, #F4F7F9 37%, #DAE3EB 63%)
      //background: linear-gradient(90deg, rgba(244, 247, 249, 0) 0%, #F4F7F9 50%, rgba(244, 247, 249, 0) 100%)
      background-size: 400% 100%
      animation: el-skeleton-loading 1.4s ease infinite

  .el-skeleton__item
    background: var(--background-secondary-soft-default)
