.el-radio-group
  display: flex
  gap: 2px

  .el-radio-button
    padding-bottom: 0
    border-left: none

    &:last-child
      margin-right: 0


  &.is-vertical
    display: flex
    flex-direction: column

    .el-radio
      margin-bottom: 24px
      display: flex
      align-items: flex-start

      &__input
        display: flex
