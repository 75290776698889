.configurations-page-wrap
  .configurations-wrap
    display: flex
    align-items: center
    margin-bottom: 24px

    @media #{$M_XS}
      flex-direction: column

    .search-input
      margin-left: auto
      border-radius: 20px
      max-width: 400px

      .el-input__inner
        border-radius: 20px
        line-height: 40px
        height: 40px

    .right-column
      @media #{$M_XS}
        margin-top: 16px

  .cards-wrap
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(440px, 1fr))
    grid-gap: 16px

    @media #{$M_XS}
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr))
