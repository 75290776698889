.el-popover
  .el-popconfirm
    color: var(--dark)

    &__main
      margin-bottom: 8px

    &__action
      display: flex
      justify-content: flex-end
      align-items: center

.mark-line-popover
  position: absolute
  z-index: 6

  .el-popover
    background-color: var(--white-dark)
    padding: 15px
    border: none
    border-radius: 4px
    color: var(--dark)
    box-shadow: var(--light-color-box-shadow)
    width: max-content

    &__title,
    .release-info
      font-family: var(--font-family)
      color: var(--dark)
      font-size: var(--font-size)

    &__title
      font-weight: 700
      margin-bottom: 5px

    .release-info
      font-size: 12px
