.content-server-modal
  .modal-body
    min-height: 100px
  
  .control-buttons
    display: flex
    justify-content: flex-end
    background: var(--white)
    border-top: 2px solid var(--white-dark)
    padding: 16px
    position: sticky
    bottom: 0
    margin-top: auto
    z-index: 1
  
  .el-dialog__body,
  .el-dialog__footer
    padding: 0
  
  .json-form
    .border
      display: none

  .form-card
    padding: 16px
  
  .form-layout .form-row .root-form-item .el-form-item,
  .forms-wrap .form-row .root-form-item .el-form-item
    margin-bottom: 0

  .el-select__tags
    width: auto !important