@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Italic.eot')
  src: local('Cera Pro Italic'), local('CeraPro-Italic')
  src: url('~@/assets/fonts/CeraPro-Italic.woff') format('woff')
  font-weight: normal
  font-style: italic


@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Regular.eot')
  src: local('Cera Pro Regular'), local('CeraPro-Regular')
  src: url('~@/assets/fonts/CeraPro-Regular.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-BlackItalic.eot')
  src: local('Cera Pro Black Italic'), local('CeraPro-BlackItalic')
  src: url('~@/assets/fonts/CeraPro-BlackItalic.woff') format('woff')
  font-weight: 900
  font-style: italic

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Light.eot')
  src: local('Cera Pro Light'), local('CeraPro-Light')
  src: url('~@/assets/fonts/CeraPro-Light.woff') format('woff')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-BoldItalic.eot')
  src: local('Cera Pro Bold Italic'), local('CeraPro-BoldItalic')
  src: url('~@/assets/fonts/CeraPro-BoldItalic.woff') format('woff')
  font-weight: bold
  font-style: italic

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Black.eot')
  src: local('Cera Pro Black'), local('CeraPro-Black')
  src: url('~@/assets/fonts/CeraPro-Black.woff') format('woff')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Medium.eot')
  src: local('Cera Pro Medium'), local('CeraPro-Medium')
  src: url('~@/assets/fonts/CeraPro-Medium.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Bold.eot')
  src: local('Cera Pro Bold'), local('CeraPro-Bold')
  src: url('~@/assets/fonts/CeraPro-Bold.woff') format('woff')
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-Thin.eot')
  src: local('Cera Pro Thin'), local('CeraPro-Thin')
  src: url('~@/assets/fonts/CeraPro-Thin.woff') format('woff')
  font-weight: 100
  font-style: normal

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-MediumItalic.eot')
  src: local('Cera Pro Medium Italic'), local('CeraPro-MediumItalic')
  src: url('~@/assets/fonts/CeraPro-MediumItalic.woff') format('woff')
  font-weight: 500
  font-style: italic

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-LightItalic.eot')
  src: local('Cera Pro Light Italic'), local('CeraPro-LightItalic')
  src: url('~@/assets/fonts/CeraPro-LightItalic.woff') format('woff')
  font-weight: 300
  font-style: italic

@font-face
  font-family: 'Cera Pro'
  src: url('~@/assets/fonts/CeraPro-ThinItalic.eot')
  src: local('Cera Pro Thin Italic'), local('CeraPro-ThinItalic')
  src: url('~@/assets/fonts/CeraPro-ThinItalic.woff') format('woff')
  font-weight: 100
  font-style: italic

// Arial Monotype

@font-face
  font-family: 'Arial Monospaced'
  src: url('~@/assets/fonts/ArialMonospaced-Bold.woff2') format('woff2')
  src: url('~@/assets/fonts/ArialMonospaced-Bold.woff') format('woff')
  src: url('~@/assets/fonts/ArialMonospaced-Bold.ttf') format('truetype')
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'Arial Monospaced'
  src: url('~@/assets/fonts/ArialMonospaced-BoldOblique.woff2') format('woff2')
  src: url('~@/assets/fonts/ArialMonospaced-BoldOblique.woff') format('woff')
  src: url('~@/assets/fonts/ArialMonospaced-BoldOblique.ttf') format('truetype')
  font-weight: bold
  font-style: italic

@font-face
  font-family: 'Arial Monospaced'
  src: url('~@/assets/fonts/ArialMonospaced.woff2') format('woff2')
  src: url('~@/assets/fonts/ArialMonospaced.woff') format('woff')
  src: url('~@/assets/fonts/ArialMonospaced.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Arial Monospaced'
  src: url('~@/assets/fonts/ArialMonospaced-Oblique.woff2') format('woff2')
  src: url('~@/assets/fonts/ArialMonospaced-Oblique.woff') format('woff')
  src: url('~@/assets/fonts/ArialMonospaced-Oblique.ttf') format('truetype')
  font-weight: normal
  font-style: italic
