.header
  .header-round-button
    .icon-wrap
      background: var(--brand-ultra-dark)
      color: #fff
      width: 40px
      height: 40px
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      font-size: 20px

      @media #{$M_XS}
        width: 32px
        height: 32px
        font-size: 16px

  .header-selector
    border-right: 2px solid var(--brand-semi-light)
    width: 250px

    @media #{$M_XS}
      flex-basis: 50%
      width: 50%

    &:last-child
      @media #{$M_XS}
        border-right: none

    .el-input, .el-input__inner
      height: 64px

      @media #{$M_XS}
        height: 40px
