.card
  border: 1px solid var(--border-neutral-light-default)
  border-radius: var(--common-border-radius)
  background: var(--white)
  display: flex
  flex-direction: column

  .content
    padding: 16px

  .footer
    border-top: 1px solid var(--border-neutral-light-default)
    padding: 10px 16px
