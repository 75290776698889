.el-loading-mask
  z-index: 2

  .dark &
    background-color: rgba(0, 0, 0, 0.7)

.el-loading-spinner
  margin-top: 0
  transform: translateY(-50%)

  .circular
    @media #{$M_XS}
      width: 36px
      height: 36px

  .el-loading-text
    font-size: 24px
    font-weight: 700
    line-height: 36px
    color: var(--text-neutral-black)


.close-loading.el-icon-circle-close
  visibility: hidden
  opacity: 0
  z-index: 2
  color: var(--gray)
  right: 50%
  transform: translate(30px, -22px)
  position: absolute
  cursor: pointer

  &:hover
    z-index: 3
    color: var(--gray-dark)

    .el-tooltip__popper.custom
      visibility: visible
      opacity: 1

  &.show
    opacity: 1
    visibility: visible!important
