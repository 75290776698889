.filter-wrapper
  padding: 8px 25px
  background: var(--white)
  display: flex
  align-items: center
  height: 64px
  width: 100%
  position: fixed
  top: 64px
  z-index: 3
  transition: box-shadow var(--transition)

  &.show-all-filters
    padding: 8px
    @media #{$M_XS}
      box-shadow: var(--dark-color-box-shadow)
      align-items: flex-start

      .mobile-filter-toggle
        margin-top: 3px

      .filter
        display: flex
        flex-direction: column
        height: auto
        max-height: calc(100vh - 100px)

        .apply-filters
          margin-top: 8px
          flex-direction: row-reverse
          justify-content: flex-end

          .apply-button
            margin-left: 0

      .tags
        display: flex
        flex-wrap: wrap
        margin-right: 0
        height: auto
        flex-direction: column
        align-items: flex-start

        .filter-item:not(:first-child),
        .add-filter-button
          margin-left: 0
          margin-top: 8px
          margin-right: 8px

  @media #{$M_XS}
    top: 80px
    min-height: 48px
    height: auto
    padding: 0 8px

  .filter
    display: flex
    overflow-x: auto
    width: 100%
    justify-content: space-between

    @media #{$M_XS}
      height: 48px

      .filter-opened &
        overflow-x: hidden

  .scrolled &
    box-shadow: var(--dark-color-box-shadow)

  .tags
    display: flex
    align-items: center
    overflow: auto
    padding-right: 8px
    position: relative
    height: 64px

    &.right-shadow:after
      right: -8px

    @media #{$M_XS}
      height: auto
      overflow: visible
      margin-right: 8px

    .filter-opened &
      overflow-x: hidden

      @media #{$M_XS}
        overflow: visible


  .filter-item
    color: var(--dark)

    &:not(:first-child)
      margin-left: 8px

    &.disabled
      pointer-events: none
      color: var(--gray)
      background: var(--white-dark)

    .main-loading-wrapper
      width: 24px
      height: 24px
      left: -10px
      margin-right: auto

      .el-loading-spinner
        margin-top: -17px
        .circular
          width: 24px
          height: 24px

  .apply-filters
    margin-left: auto
    position: relative
    display: flex
    align-items: center

    @media #{$M_XS}
      margin-left: 0
      width: auto

    .apply-button
      margin-left: 10px

      &:not([disabled])
        animation: accent .5s linear

      @media #{$M_XS}
        height: 27px

  .show-filters-button
    margin-right: auto

  .mobile-filter-toggle
    display: none
    position: relative
    z-index: 50
    padding: 7px
    min-width: auto
    line-height: 0
    color: var(--brand)
    background-color: transparent
    margin-right: 8px

    &:focus,
    &:hover,
    &:active
      color: var(--brand)
      background-color: transparent

    @media #{$M_XS}
      display: block

@keyframes accent
  0%
    box-shadow: 0 0 5px var(--brand)

  100%
    box-shadow: 0 0 0 var(--brand)
