.form-layout, .forms-wrap
  .form-wrap
    margin-top: 35px

  .form-card
    padding: 24px
    margin-bottom: 0
    border-radius: var(--common-border-radius)
    background-color: var(--white)

    &:not(last-child)
      margin-bottom: 16px

    h2
      font-size: 16px
      min-height: 64px
      display: flex
      align-items: center
      margin: -24px -24px 35px
      padding: 24px

      &.border
        border-bottom: 2px solid var(--ultra-light)

  .row-wrap
    & + .row-wrap, & + .expandable-inputs
      margin-top: 16px

  .form-row
    width: 100%
    display: flex

    & + .form-row
      margin-top: 28px

    .root-form-item
      display: flex
      flex-grow: 1
      flex-basis: 100%
      flex-direction: column
      width: 0 // hack to make width limit with overflow scroll. Check removed items in pre removed creatives

      & + .root-form-item
        margin-left: 34px

        .el-form-item
          &.is-checkbox:only-child
            margin-top: 34px

      &:has(.is-checkbox) + .root-form-item
        .card-template-inputs
          .is-checkbox:not(.is-checkbox.checkbox-top-margin):only-child
            margin-top: 0

      .el-form-item
        width: 100%
        margin-bottom: 8px

        .el-checkbox
          display: inline-flex
          width: auto

          &__label
            white-space: normal
            font-weight: bold
            line-height: 17px

        & + .el-form-item
          .el-form-item__label
            margin-top: 8px

        .el-button:focus,
        .el-input input:focus
          box-shadow: var(--light-box-shadow)
          transition: box-shadow var(--transition)

        .el-upload:focus
          border-color: var(--brand)
          transition: border-color var(--transition)

        .el-checkbox__input.is-checked.is-focus
          box-shadow: var(--brand-color-box-shadow)
          transition: box-shadow var(--transition)


.forms-wrap
  margin-top: 40px
  display: grid
  grid-gap: 16px
  grid-template-columns: 300px 1fr

  @media #{$M_XS}
    grid-template-columns: 1fr

// Custom classes from BE
.align-items-to-bottom
  .root-form-item
    margin-top: auto
