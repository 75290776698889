.el-checkbox-group
  .el-checkbox-button
    &:not(:first-child)
      border-left: 2px solid var(--white)

    &.is-disabled.is-checked
      .el-checkbox-button__inner    
        background-color: var(--light)
        color: var(--gray-dark)
      
    &.is-disabled
      .el-checkbox-button__inner        
        background-color: var(--white-dark)
        color: var(--gray)