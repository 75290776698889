.auth-layout
  min-height: 100vh
  display: flex
  flex-wrap: wrap
  align-content: flex-start

  .auth-page
    display: flex
    flex-direction: column
    flex-wrap: wrap
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    padding: 0 20px

    &__wrapper
      display: flex
      flex-wrap: wrap
      justify-content: center
      width: 100%
      max-width: 480px

    .el-form-item
      .el-input__inner
        height: 48px

  .sign-up-page,
  .invite-page
    &__wrapper
      max-width: 480px

    .el-form-item
      margin-bottom: 20px

      &.form-item__button
        margin-bottom: 0

      .el-input__inner
        height: 36px

    .icon-eye
      top: 8px

  .center-layout
    height: calc(100vh - 76px)
    align-content: center

  h1
    font-size: 40px
    line-height: 1.5
    font-weight: 700
    color: var(--text-neutral-black)

    @media #{$M_SM}
      font-size: 32px

  .form-title
    display: block
    width: 100%
    text-align: center

  .post-title
    display: inline-block
    font-size: 20px
    line-height: 1.6
    text-align: center
    margin-bottom: 32px

  .el-checkbox
    &__label
      text-wrap-mode: wrap
      vertical-align: middle

  .action-button
    width: 100%
    height: 48px
    border-radius: 24px
    font-size: 16px

  .validation-wrapper
    width: 100%

  .info-panel
    margin-bottom: 48px

  .password-field
    position: relative

    .el-input__inner
      letter-spacing: 5px
      padding-right: 40px

      &[type="text"]
        letter-spacing: 1px

      &::placeholder
        letter-spacing: 0

  .form
    width: 100%

  .form-item
    margin-bottom: 0

    &__button
      margin-bottom: 24px

  .el-form-item
    &.form-item
      margin-bottom: 8px

    &__default
      color: var(--text-secondary-default)
      font-size: 12px

    &__error
      display: inline-block
      margin-top: 4px

    &__label
      width: 100%
      padding-bottom: 8px

  .el-input__inner
    border-color: var(--border-neutral-light-default)

    &::placeholder
      color: var(--text-secondary-default)
      opacity: 1

  .link
    color: var(--text-primary-default)

    &__center
      display: flex
      justify-content: center
      align-items: center
      font-size: 14px

  .highlighter
    width: 100%
    background: var(--background-secondary-soft-default)
    border-radius: 8px
    margin-bottom: 32px
    padding: 16px 32px
    font-size: 20px
    line-height: 1.2
    font-weight: 700
    text-align: center
    white-space: normal
    word-wrap: break-word
    color: var(--text-neutral-black)

  .divider
    width: 100%
    text-align: center
    position: relative
    margin: 24px 0

    &:before,
    &:after
      content: ''
      position: absolute
      top: 50%
      border: 1px solid var(--border-neutral-light-default)
      width: 46%

    &:before
      left: 0
    &:after
      right: 0

  .icon-eye
    position: absolute
    right: 16px
    top: 14px
    font-size: 20px
    color: var(--icon-secondary-default)
    cursor: pointer

.el-checkbox
  &.required
    &::before
      content: "*"
      color: var(--text-error-default)
      margin-right: 4px
      font-size: 14px
      font-weight: 700

.is-error
  .el-checkbox
    &__label
      color: var(--dark)
    &__inner
      border: 1px solid var(--border-error-default)

.password-field-provider
  position: relative
