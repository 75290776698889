.el-tabs
  &__item
    &:hover, &.is-active
      color: var(--brand)

  &__active-bar
    background-color: var(--brand)

.main-menus-wrapper
  .el-tabs
    &__header
      margin: 0

    &__nav
      @media #{$M_XS}
        display: flex
        width: 100%

    &__nav-wrap
      margin-bottom: 0

      &::after
        content: none

    &__item
      display: inline-flex
      justify-content: center
      align-items: center
      font-size: 16px
      font-weight: normal
      height: 64px
      min-width: 225px
      color: var(--white)
      transition: background var(--transition)

      @media #{$M_XS}
        min-width: auto
        font-size: 14px
        height: 48px
        flex-basis: 100%

      &:focus,
      &:focus.is-active.is-focus:not(:active)
        box-shadow: var(--dark-box-shadow)

      &.is-active
        font-weight: bold
        color: var(--white)
        background: var(--dark)

      &.is-disabled
        opacity: .3
        pointer-events: none
        cursor: default
        color: var(--white)

    &--card
      & > .el-tabs__header
        border-top: 1px solid var(--brand-dark)
        border-bottom: none

      & > .el-tabs__header .el-tabs__nav,
      & > .el-tabs__header .el-tabs__item
        border: none

    &.dark
      .el-tabs__item:hover
        color: var(--white)
