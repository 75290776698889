.el-select-group
  &__wrap
    border-bottom: 1px solid var(--gray-ultra-light)

    &:not(:last-of-type)
      padding-bottom: 0

      &:after
        display: none

    & + .el-select-group__wrap
      .el-select-group__title
        padding-top: 6px

  &__title
    padding: 0 20px
    font-size: 14px
    font-weight: 700
    color: var(--brand-dark)
