@import "@/styles/common/_sass_variables.sass"
// SASS VARIABLES

$M_XS: #{'screen and (max-width: 825px)'} // phones
$M_SM: #{'screen and (max-width: 991px)'} // tablets
$M_MD: #{'screen and (max-width: 1199px)'} // medium desktop
$M_LG: #{'screen and (min-width: 1200px)'} // large desktop
.mag
  &-is-hidden
    display: none !important

  &-flex-nowrap
    flex-wrap: nowrap

  &-flex-wrap
    flex-wrap: wrap
// CSS VARIABLES
\:root
  // COLORS
  --black: #000000
  --white: #FFFFFF
  --white-dark: #F2F4F8
  --ultra-light: #E7E9EF
  --light: #C8CCD9
  --gray: #A0ABC4
  --gray-dark: #6E7A9A
  --gray-dark-light: #D7DAE3
  --gray-light: #F8FAFB
  --gray-ultra-light: #ECEFF3
  --dark: #0D122E
  --dark-transparent: rgba(0, 0, 0, 0.04)
  --brand: #3652F1
  --brand-transparent: #3652F1b5
  --brand-light: #809CFF
  --brand-light-transparent: #809CFFb5
  --brand-dark-light: #1A31B4
  --brand-semi-light: #222E67
  --brand-dark: #192354
  --brand-semi-dark: #18214E
  --brand-mega-dark: #151D45
  --brand-ultra-dark: #11183A
  --brand-ultra-dark-transparent: rgba(17, 24, 59, 0.5)
  --brand-shadow-color: rgba(54, 82, 241, 0.3)
  --red: #be4680
  --red-light: #DB81A7
  --android-green: #a4c639
  --green: #24AA8F
  --green-dark: #17823B
  --green-dark-transparent: rgb(23, 130, 59, 0.1)
  --red-lighter: #D25973
  --red-attention: #EB5757
  --disabled-background: #F5F7FA
  --disabled-text: var(--gray-dark)
  --disabled-border: #E4E7ED
  --funnels-current-visual: #3247A8
  --funnels-previous-visual: rgba(50, 71, 168, 0.5)

  // COMMON
  --input-border-radius: 4px
  --common-border-radius: 8px
  --button-border-radius: 4px
  --button-round-border-radius: 16px
  --transition: .3s ease
  --brand-color-box-shadow: 0px 2px 6px var(--brand-shadow-color)
  --dark-color-box-shadow: 0px 2px 6px rgba(13, 18, 46, 0.3)
  --light-color-box-shadow: 0px -3px 3px var(--dark-transparent), 0px 2px 4px var(--dark-transparent), 0px 2px 4px var(--dark-transparent), 0px 4px 8px var(--dark-transparent), 0px 8px 16px var(--dark-transparent)
  --dark-color-tooltip-box-shadow: 0px 1px 1px rgba(25, 35, 84, 0.06), 0px 2px 2px rgba(25, 35, 84, 0.06), 0px 4px 4px rgba(25, 35, 84, 0.06), 0px 8px 8px rgba(25, 35, 84, 0.06), 0px 16px 16px rgba(25, 35, 84, 0.06)
  --retention-table-cell-color-rgb: 50, 71, 168
  --dark-box-shadow: inset 0 0 2px 2px var(--brand-semi-light)
  --light-box-shadow: inset 0 0 2px 1px var(--gray-ultra-light)

  // FONT
  --font-family: 'Cera Pro', sans-serif
  --font-family-Arial-Mono: 'Arial Monospaced', sans-serif
  --font-size: 14px

  // CHARTS
  --axis-label-font-size: 12
  --axis-label-x-margin: 24
  --axis-label-y-margin: 18

@media #{$M_XS}
  \:root
    --font-size: 12px
    --axis-label-font-size: 10
    --axis-label-x-margin: 12
    --axis-label-y-margin: 12


// NEW STYLING
\:root
  // background colors
  --background-neutral-white: #FFFFFF
  --background-neutral-black: #0D122E
  --background-secondary-default: #AABBCF
  --background-neutral-white-hover: #DCE2FD
  --background-neutral-white-pressed: #C0CDFD
  --background-primary-soft-default: #DCE2FD
  --background-primary-soft-hover: #C0CDFD
  --background-primary-default: #3652F1
  --background-secondary-soft-default: #EBF0F4
  --background-secondary-soft-disabled: #F4F7F9
  --background-secondary-soft-hover: #DAE3EB
  --background-secondary-soft-pressed: #C3D1DE
  --background-success-default: #3EC372
  --background-success-soft-default: #E0F8E9
  --background-warning-default: #F0851F
  --background-warning-soft-default: #FDF0D7
  --background-error-soft-default: #FDE3E3
  --background-error-default: #EB5757
  --background-fuschsia-soft-default: #F7ECFB

  // text colors
  --text-primary-default: #3652F1
  --text-primary-disabled: #95AEFB
  --text-secondary-default: #6E7A9A
  --text-secondary-hover: #58627B
  --text-secondary-disabled: #AABBCF
  --text-secondary-pressed: #4B5364
  --text-neutral-white: #FFFFFF
  --text-neutral-black: #0D122E
  --text-neutral-disabled: #94A4C0
  --text-success-default: #3EC372
  --text-error-default: #EB5757
  --text-warning-default: #F0851F

  // icon colors
  --icon-primary-default: #3652F1
  --icon-neutral-primary: #181853
  --icon-secondary-default: #94A4C0
  --icon-secondary-hover: #6E7A9A
  --icon-secondary-light-defualt: #AABBCF
  --icon-success-default: #3EC372
  --icon-warning-default: #F0851F
  --icon-error-default: #EB5757
  --icon-fuschsia-default: #C062D3

  // border colors
  --border-neutral-light-default: #DAE3EB
  --border-neutral-light-disabled: #EBF0F4
  --border-neutral-light-hover: #C3D1DE
  --border-neutral-white-hover: #DCE2FD
  --border-neutral-white-pressed: #C0CDFD
  --border-primary-disabled: #C0CDFD
  --border-primary-default: #3652F1
  --border-error-default: #EB5757

  // transitions
  --transition-disolve: 0.150s ease-out

  // surface colors
  --surface-secondary-light: #F4F7F9

  // box shadows
  --shadow-level-1: 0 2px 4px 0 rgba(13, 18, 46, 0.16)
  --shadow-level-4: 0 4px 16px 0 rgba(13, 18, 46, 0.32)
  --shadow-level-5: 0 8px 24px 0 rgba(13, 18, 46, 0.32)


.loader-wrapper
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0
  z-index: 2000
  display: flex
  align-items: center
  justify-content: center
  opacity: 0
  transition: opacity var(--transition)
  pointer-events: none
  min-height: 60px

  &.loading
    opacity: .75
    pointer-events: auto

  &.full-screen
    position: fixed
    width: 100%
    height: 100%
    z-index: 99999

    #rocket
      height: 300px

  &.overlay
    background: rgba(0, 0, 0, 0.7)

    &.dark
      background: rgba(255, 255, 255, 0.7)

  #rocket
    height: 50px
