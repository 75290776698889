.el-radio
  color: var(--dark)
  font-weight: 400

  &__input
    &.is-checked, &.is-focus
      .el-radio__inner
        background-color: transparent
        border-color: var(--brand)

        &:after
          background-color: var(--brand)

    &.is-checked
      & + .el-radio__label
        color: var(--dark)

    &.is-disabled
      & + span.el-radio__label
        color: var(--gray-dark)

  &__inner
    border: 2px solid var(--light)
    width: 16px
    height: 16px

    @media #{$M_XS}
      width: 14px
      height: 14px

    &:after
      width: 6px
      height: 6px
      background-color: transparent

      @media #{$M_XS}
        width: 5px
        height: 5px

  &:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner
    box-shadow: none

  &:not(.is-disabled):hover
    .el-radio__inner
      border-color: var(--brand)

  &.is-disabled
    cursor: not-allowed

  @media #{$M_XS}
    &__label
      font-size: 12px
