.grabber-card
  &:not(:first-child)
    margin-top: 16px

  &:not(.grabber-list-wrapper)
    padding: 30px 24px 30px 60px


  .has-nested-components > &
    border-bottom: 2px solid var(--ultra-light)

  .content
    display: flex
    align-items: center
    padding: 0

  .title
    margin-left: 16px
    width: 35%

  .info
    color: var(--gray-dark)
    display: flex
    align-items: center
    margin-left: 30px

    b
      margin-right: 4px

  .edit-button
    margin-left: auto

  .icon-attention
    font-size: 16px
    color: var(--red-attention)
    margin-right: 16px


.grabber-list-wrapper
  border: 2px solid var(--ultra-light)
  border-radius: var(--input-border-radius)
  padding: 25px 32px
  margin: 40px 104px

  .accordion-title
    display: flex
    align-items: center
    cursor: pointer
    font-size: var(--font-size)
    color: var(--brand)

    &.expanded
      &:after
        transform: rotate(270deg)

    &:after
      content: ''
      width: 6px
      height: 10px
      background-color: currentColor
      mask-size: contain
      mask-image: url("~@/static/arrow-line.svg")
      transform: rotate(90deg)
      margin-left: 16px

  .total
    background: var(--brand)
    color: var(--white)
    border-radius: 50%
    display: inline-flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    font-size: 12px
    line-height: 1
    width: 14px
    height: 14px
    margin-right: 10px

  &.grabber-card
    .content
      background: var(--gray-light)
      border-radius: var(--common-border-radius)
      padding: 30px 24px 30px 60px
      display: flex
      align-items: center

      &:not(:first-child)
        margin-top: 16px

    .title
      margin-left: 16px
      width: 35%

    .info
      color: var(--gray-dark)
      display: flex
      align-items: center
      margin-left: 30px

      b
        margin-right: 4px

    .icon-attention
      font-size: 16px
      color: var(--red-attention)
      margin-right: 16px
