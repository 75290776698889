.el-pagination
  &.is-background
    display: flex
    align-items: center

    @media #{$M_XS}
      margin-bottom: 8px

    .btn-prev,
    .btn-next,
    .el-pager li
      display: flex
      justify-content: center
      align-items: center
      min-width: 36px
      height: 36px
      padding: 0
      margin: 0
      border: 2px solid var(--border-neutral-light-default)
      border-radius: 4px
      font-size: 14px
      line-height: 1
      font-weight: 400
      background-color: var(--background-neutral-white)

    .btn-prev,
    .btn-next,
      .el-icon
        font-size: 16px

    .btn-prev:disabled,
    .btn-next:disabled
      background-color: var(--background-secondary-soft-disabled)
      border: none

    .btn-prev:disabled:hover,
    .btn-next:disabled:hover
      border: none

    .btn-prev,
    .btn-next
      .el-icon:before
        color: var(--icon-secondary-default)

    .btn-prev:hover,
    .btn-next:hover,
    .el-pager li:hover
      border: 2px solid var(--border-neutral-light-hover)
      color: var(--text-neutral-black) !important

    .el-pager
      display: flex
      gap: 4px
      margin: 0 4px

      li
        color: var(--text-neutral-black)

        &.more
          &:before
            content: '...' !important
            font-family: var(--font-family)
            font-size: 14px

        &:not(.disabled)
          &.active
            border: 2px solid var(--border-primary-default)
            background-color: var(--background-primary-soft-default)
            color: var(--text-primary-default)
            font-weight: 700

  &__jump
    height: 36px !important
    color: var(--text-neutral-black)
    font-weight: 700
    font-size: 14px

  &__editor.el-input
    width: 48px
    margin-left: 8px

    .el-input__inner
      height: 36px
