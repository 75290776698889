.article-content,
.hint-content
  line-height: 1.4
  margin: revert
  overflow: auto
  word-wrap: break-word
  
  *, *::before, *::after
    box-sizing: revert

  table
    border-collapse: collapse
    width: 100%
    border-color: #ccc
    border-style: inset

    &:not([cellpadding])
      td, th
        padding: .4rem

    &[border]:not([border="0"])
      &:not([style*=border-width])
        td, th
          border-width: 1px

      &:not([style*=border-style])
        td, th
          border-style: solid

      &:not([style*=border-color])
        td, th
          border-color: #ccc

  figure
    display: table
    margin: 1rem auto

    figcaption
      color: #999
      display: block
      margin-top: .25rem
      text-align: center

  hr
    border-color: #ccc
    border-style: solid
    border-width: 1px 0 0 0

  &:not([dir=rtl]) blockquote
    border-left: 2px solid var(--gray-dark-light)
    margin-left: 1.5rem
    padding-left: 1rem

  &[dir=rtl] blockquote
    border-right: 2px solid var(--gray-dark-light)
    margin-right: 1.5rem
    padding-right: 1rem

  a
    color: var(--brand)

    &:active,
    &:hover
      color: var(--dark)

  h1, h2, h3, h4, h5, h6
    margin: revert
    font-size: revert
    font-weight: revert

  ul, ol, dd
    margin: revert
    padding: revert
    list-style: revert

  pre, code
    font-size: 13px

  code
    background-color: var(--ultra-light)
    border-radius: 3px
    padding: 0 .2rem

.hint-content
  width: 100%
  display: block
  overflow: auto

  p, h1, h2, h3, h4, h5, h6, pre, code
    margin: revert
    margin-top: 5px
    margin-bottom: 5px

    &:first-child
      margin-top: 0

    &:last-child
      margin-bottom: 0