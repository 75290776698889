.scrollable-shadow
  position: relative

  &:hover, &.static-shadow
    &:before, &:after
      opacity: 1

  &:before, &:after
    content: ''
    position: sticky
    display: none
    opacity: 0
    transition: opacity var(--transition)
    pointer-events: none
    flex-shrink: 0
    z-index: 1


  //Horizontal Scroll
  &.top-shadow, &.bottom-shadow
    &:before, &:after
      height: 20px
      width: 100%

  &.top-shadow
    &:before
      display: flex

  &.bottom-shadow
    &:after
      display: flex

  &.top-shadow
    &:before
      background: linear-gradient(to bottom, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
      top: 0
      margin-bottom: -20px

    &.dark
      &:before
        background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)

    &.dark-blue
      &:before
        background: linear-gradient(to bottom, rgba(13, 18, 46, 1) 0%, rgba(13, 18, 46, 0) 100%)

    &.brand-dark
      &:before
        background: linear-gradient(to bottom, rgba(25, 35, 84, 1) 0%, rgba(25, 35, 84, 0) 100%)

  &.bottom-shadow
    &:after
      background: linear-gradient(to top, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
      bottom: 0
      margin-top: -20px

    &.dark
      &:after
        background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)

    &.dark-blue
      &:after
        background: linear-gradient(to top, rgba(13, 18, 46, 1) 0%, rgba(13, 18, 46, 0) 100%)

    &.brand-dark
      &:after
        background: linear-gradient(to top, rgba(25, 35, 84, 1) 0%, rgba(25, 35, 84, 0) 100%)

  //Vertical Scroll
  &.left-shadow, &.right-shadow
    &:before, &:after
      align-self: stretch
      height: auto
      width: 20px

  &.left-shadow
    &:before
      display: block

    &.dark
      &:before
        background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)

    &.dark-blue
      &:before
        background: linear-gradient(to right, rgba(13, 18, 46, 1) 0%, rgba(13, 18, 46, 0) 100%)

    &.brand-dark
      &:before
        background: linear-gradient(to right, rgba(25, 35, 84, 1) 0%, rgba(25, 35, 84, 0) 100%)

  &.right-shadow
    &:after
      display: block


  &.left-shadow
    &:before
      background: linear-gradient(to right, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
      left: 0
      margin-right: -20px

  &.right-shadow
    &:after
      background: linear-gradient(to left, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
      right: 0
      margin-left: -20px
