.fake-select-length
  font-weight: bold
  font-family: var(--font-family)
  font-size: var(--font-size)
  padding: 0 35px 0 15px
  display: block
  position: absolute
  pointer-events: none
  white-space: nowrap
  opacity: 0
