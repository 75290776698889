.el-checkbox-button
  &:focus-within
    box-shadow: var(--brand-color-box-shadow)

  &:first-child
    .el-checkbox-button__inner
      border-left: none

  &--medium
    .el-checkbox-button__inner
      font-weight: bold

  &__orig-checkbox
    &:checked
      & + .el-checkbox-button__inner
        background-color: var(--brand)
        box-shadow: var(--brand-color-box-shadow)!important
        border-color: transparent

    &:disabled
      & + .el-checkbox-button__inner
        background-color: var(--white-dark)
        color: var(--light)

  &__inner
    background: var(--white-dark)
    border: none
    color: var(--brand)
    font-size: 15px
    font-weight: 400
    padding: 7px

    &:hover
      background-color: var(--brand)
      color: var(--white)

  &__original
    &:focus:not(:checked) + span
      background-color: var(--white-dark)
      color: var(--brand)


  &:only-child
    .el-checkbox-button__inner
      border-radius: 4px

  &.is-checked
    .el-checkbox-button__inner
      background-color: var(--brand)
      color: var(--white)
