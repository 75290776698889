.el-dialog
  border-radius: var(--common-border-radius)
  box-shadow: var(--shadow-level-5)

  @media #{$M_XS}
    width: 80%

  &__header
    padding: 20px

  &__body
    color: var(--text-neutral-black)
    font-size: 14px
    line-height: 1.71
    padding: 0 20px
    word-break: break-word

  &__footer
    padding: 20px

  &__title
    font-size: 20px
    font-weight: bold
    color: var(--dark)

  &__close
    &:before
      content: ''
      mask-image: url("~@/static/close.svg")
      background-color: var(--icon-secondary-default)
      width: 20px
      height: 20px
      display: block
      transition: background-color var(--transition-disolve)

    &:hover
      &:before
        background-color: var(--icon-secondary-hover)

  &.video-dialog-wrap
    background: transparent
    border: none

    .el-dialog__body
      display: flex
      align-content: center
      justify-content: center

    .el-dialog__header
      display: none
