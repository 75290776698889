.el-message-box
  border-radius: var(--common-border-radius)
  border: 2px solid var(--white-dark)
  box-shadow: none

  @media #{M_XS}
    max-width: 90vw

  &__header
    border-bottom: 2px solid var(--white-dark)
    padding-bottom: 18px

  &__title
    font-size: var(--font-size)
    font-weight: bold
    color: var(--dark)

  &__content
    color: var(--dark)

  &__headerbtn
    .el-message-box__close
      color: var(--gray-dark)
      transition: var(--transition)

    &:hover, &:focus
      .el-message-box__close
        color: var(--brand)

  &.archive-inventory
    width: max-content
    max-width: 100%
