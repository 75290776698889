.el-date-picker,
.el-date-range-picker
  &__header
    margin: 15px 14px 0
    padding-bottom: 0

    .el-icon-d-arrow-left:before
      content: '\e6de'

    .el-icon-d-arrow-right:before
      content: '\e6e0'

  &__header-label
    color: var(--dark)
    font-size: var(--font-size)
    font-weight: bold

    &:hover
      color: var(--dark)
      opacity: .75

  &__header--bordered
    border-bottom: none

  .el-picker-panel__icon-btn
    font-size: 11px
    padding: 9px
    margin-top: 0

    &:hover
      background-color: var(--white-dark)
      opacity: .5
      color: var(--dark)

  .el-year-table td
    padding: 14px 3px

  .el-year-table,
  .el-month-table
    td div
      height: 36px
      padding: 6px 0

    .cell
      position: relative
      color: var(--dark)
      font-size: var(--font-size)
      border-radius: 0
      width: 100%
      height: 36px
      line-height: 36px

      &:hover
        color: var(--dark)
        background-color: var(--white-dark)

    td.current:not(.disabled)
      .cell
        font-weight: normal
        color: var(--white)
        background-color: var(--brand)
        border-radius: 4px

    td.disabled,
    td:empty
      cursor: auto

      .cell
        color: var(--gray)
        cursor: auto
        pointer-events: none
        background-color: transparent

    td.today:not(.current)
      .cell
        color: var(--dark)
        font-weight: normal

        &:after
          display: block
          position: absolute
          content: ""
          width: 70%
          left: 15%
          height: 2px
          bottom: -2px
          background-color: var(--brand)

.el-date-picker
  width: 259px

  .el-picker-panel__content
    width: 206px
    margin: 5px 25px 20px

.el-date-range-picker
  width: 493px

  .el-picker-panel__body
    min-width: 400px

  &__header
    margin: 6px -11px 7px

    div
      color: var(--dark)
      font-size: var(--font-size)
      font-weight: bold

  &__content
    padding-top: 8px
    padding-bottom: 20px
    padding-left: 24px
    padding-right: 24px

    &.is-left
      padding-right: 15px
      border-right: none

    &.is-right
      padding-left: 15px

  td.in-range
    &.today div .cell
      color: var(--white)!important

      &:after
        display: none!important

    div
      background-color: transparent

      &:hover
        background-color: transparent

      .cell
        color: var(--white)
        background-color: var(--brand-light)

        &:hover
          background-color: var(--brand-light-transparent)
          color: var(--white)

    &.start-date
      div
        border-top-left-radius: 0
        border-bottom-left-radius: 0

        .cell
          background-color: var(--brand)
          border-top-left-radius: 4px
          border-bottom-left-radius: 4px

          &:hover
            background-color: var(--brand-transparent)

    &.end-date
      div
        border-top-right-radius: 0
        border-bottom-right-radius: 0

        .cell
          background-color: var(--brand)
          border-top-right-radius: 4px
          border-bottom-right-radius: 4px

          &:hover
            background-color: var(--brand-transparent)
