.el-select-dropdown
  background: var(--white)
  border: none
  box-shadow: var(--shadow-level-4)
  border-radius: var(--common-border-radius)

  &.el-popper
    &[x-placement^=bottom]
      margin-top: 0

    &[x-placement^=top]
      margin: 0
      margin-bottom: -2px
      margin-top: 5px

  &__item,
  .group-wrapper
    font-size: var(--font-size)
    line-height: 1.25
    transition: background var(--transition)
    white-space: normal
    word-break: break-word

  &__item
    height: auto
    display: flex
    padding: 8px 16px
    color: var(--dark)
    align-items: center

    &.selected
      color: var(--dark)

    &.hover, &:hover
      background: var(--white-dark)

  &__empty
    font-size: var(--font-size)
    padding: 20px

  .group-wrapper
    padding: 0 20px
    display: flex
    align-items: center

  // LIGHT STYLE
  &.light
    background-color: var(--white-dark)
    border: none
    border-top: 2px solid var(--ultra-light)

    .el-select-dropdown__item,
    .group-wrapper
      color: var(--dark)

      &.hover
        background: var(--ultra-light)

      &.selected
        color: var(--dark)

  // DARK STYLE
  &.dark
    background: var(--brand-dark)
    border: none
    .el-select-dropdown__item,
    .group-wrapper
      color: var(--light)

      &:hover,
      &.hover
        background: var(--brand-mega-dark)

      &.selected
        color: var(--white)

    .el-select-dropdown__empty
      color: var(--gray-dark)

    &.mode-select .el-select-dropdown__list
      border: 2px solid var(--gray-dark)

      .el-select-dropdown__item
        padding: 8px 20px

  // WHITE DARK STYLE
  &.white-dark
    border: 2px solid var(--white-dark)

  // HEADER STYLES
  &.header
    max-width: 250px
    border-radius: 0
    border-top: 2px solid var(--brand-semi-light)

    .el-select-dropdown__item,
    .group-wrapper
      padding: 18px 15px
      display: flex
      height: auto

      @media #{$M_XS}
        padding: 12px 15px

      &.selected
        .icon
          color: var(--brand)

    .group-wrapper
      .icon
        color: var(--gray-dark)
        font-size: var(--font-size)
        margin-right: 13px

    .item-wrapper
      display: flex
      align-items: center

      .icon
        font-size: var(--font-size)
        margin-right: 13px
        flex-shrink: 0

  .el-select-dropdown__item
    &.is-disabled
      color: var(--light)

      &:hover
        background: transparent

  // Multiple config
  &.is-multiple
    .el-select-dropdown__item
      &.selected
        color: var(--dark)
        background: transparent

        &.hover
          background: var(--ultra-light)

          &.is-disabled
            background: transparent

      &.is-disabled
        color: var(--light)

        &:hover
          background: transparent

  &.in-editable-uploader
    margin-bottom: 0

    .el-select-dropdown__item
      font-size: 12px
      padding: 6px 15px

    .el-select-dropdown__empty
      font-size: 12px
      padding: 15px
