.btn
  border-radius: var(--button-border-radius)
  padding: 8px 15px
  font-weight: 700
  font-size: var(--font-size)
  min-width: 100px
  text-align: center
  color: var(--brand)
  background: var(--white)
  transition-duration: .3s
  transition-property: color, background

  &:hover, &:focus, &:active
    background: var(--brand)
    color: var(--white)

  &.disabled, &[disabled], &.is-disabled
    color: var(--gray)
    background: var(--white)
    cursor: default

  &.gray
    background: var(--white-dark)

    &:hover, &:focus, &:active
      background: var(--brand)
      color: var(--white)

    &.disabled, &[disabled]
      color: var(--gray)
      background: var(--white-dark)

  &.blue
    background: var(--brand)
    color: var(--white)

    &:hover, &:focus, &:active
      background: var(--brand-dark-light)

    &.disabled, &[disabled]
      color: var(--gray)
      background: var(--white-dark)

  &.string
    background: none
    color: var(--brand)

    &:hover, &:focus, &:active
      background: none
      color: var(--brand-dark-light)

    &.disabled, &[disabled]
      color: var(--gray)
      background: none
